/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Modal, NativeSelect } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import image from '../../../../assets/img/fakt-logo_360.png';
import { formatDate2 } from '../../../../utils/commonFunc';
import { toast } from 'react-toastify';
import axios from 'axios';

const contentMap = {
  'Lön ink. semesterersättning': {
    swe: 'Lön ink. semesterersättning',
    eng: 'Hourly wage including holiday compensation',
  },
  'Övrig bruttolön, (resor/traktamente)': {
    swe: 'Övrig bruttolön, (resor/traktamente)',
    eng: 'Other Gross Salary (travel/allowances)',
  },
  Utlägg: {
    swe: 'Utlägg',
    eng: 'Expenses1',
  },
  'Semester sparad månadsavlönad': {
    swe: 'Semester sparad månadsavlönad',
    eng: 'Saved monthly salary during vacation',
  },
};

const PaySlipModal = ({ salarySlipData, downloadModalOpen, setDownloadModalOpen, setLoading }) => {
  const [preferredLang, setPreferredLang] = useState('swe');
  const [userDetails, setUserDetails] = useState({});

  const handleCalendarDownloadPdf = () => {
    setLoading(true);

    const element = document.getElementById('Save_salaryDetails');
    if (element) {
      html2canvas(element, { scale: 3 }).then((canvas) => {
        const imgWidth = 210;
        const pageHeight = 297; // A4 size in mm
        const { width, height } = canvas;
        const aspectRatio = height / width;
        const imgHeight = imgWidth * aspectRatio;
        let heightLeft = imgHeight;
        const doc = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        // Adding first page
        doc.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(15);
        heightLeft -= pageHeight;
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
          doc.setLineWidth(15);
          heightLeft -= pageHeight;
        }
        toast.success('Downloaded successfully');
        doc.save(`${salarySlipData?.userId}-${salarySlipData?.employeeName}-salary-slip.pdf`);

        setLoading(false);
        setDownloadModalOpen(false);
      });
    }
  };

  const getBankDetails = (data) => {
    if (!data) return null;

    const isForeignBank = data?.userBank === 'Foreign bank BIC / IBAN';

    if (isForeignBank) {
      return {
        iban: data?.ibanNumber,
        bic: data?.bicNumber,
        account: null,
        clearing: null,
      };
    } else {
      return {
        iban: null,
        bic: null,
        account: data?.accountNumber,
        clearing: data?.clearingNumber,
      };
    }
  };

  const bankDetails = getBankDetails(salarySlipData);

  const getUserDetails = async () => {
    try {
      const res = await axios.get(`/admin/user/user/${salarySlipData?.userId}`);
      setUserDetails(res.data.response.user);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if(salarySlipData?.userId)
    getUserDetails();
  }, [salarySlipData]);

  return (
    <Modal
      open={downloadModalOpen}
      onClose={() => {
        setDownloadModalOpen(!downloadModalOpen);
        setPreferredLang('swe');
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '1000px',
          maxHeight: '90vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 24,
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {preferredLang === 'swe' ? 'Löneinformation' : 'Salary Detail'}
          </h5>

          <div className="ml-4">
            <FormControl>
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: 'lang',
                  id: 'uncontrolled-native',
                }}
                value={preferredLang}
                onChange={(e) => setPreferredLang(e.target.value)}
              >
                <option value="swe">Swedish</option>
                <option value="eng">English</option>
              </NativeSelect>
            </FormControl>
          </div>

          <button
            onClick={() => {
              setDownloadModalOpen(!downloadModalOpen);
              setPreferredLang('swe');
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="Save_salaryDetails">
          <div className="modal-body">
            <div className="page-content EconomyInvoices">
              <div className="">
                <div className="invoice-details-part-container flex-column mt-1 w-100">
                  <div className="invoice-details-wrapper-1">
                    <div className="invoice-details-row-container row">
                      <div className="col-md-6 col-sm-6 col-12">
                        <span className="invoice-details-title ml-3">
                          <img src={image} alt="site-logo" loading="lazy" />
                        </span>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="invoice-details-row-heading">
                          <p className="mb-3" style={{ color: 'rgb(83, 83, 83)' }}>
                            <strong>{preferredLang === 'swe' ? 'Lönebesked' : 'Salary notice'}</strong>{' '}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-12 p-3" />

                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="invoice-details-row">
                          <p className="mb-2" style={{ color: 'rgb(83, 83, 83)' }}>
                            <strong>{salarySlipData?.employeeName}</strong>{' '}
                          </p>
                        </div>
                        <div className="invoice-details-row">
                          <p className="mb-2" style={{ color: 'rgb(83, 83, 83)' }}>
                            <strong>{userDetails?.address}</strong>{' '}
                          </p>
                        </div>
                        <div className="invoice-details-row">
                          <p className="mb-2" style={{ color: 'rgb(83, 83, 83)' }}>
                            <strong>
                              {userDetails?.zipCode} {userDetails?.city}
                            </strong>{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between flex-wrap  mb-3">
                <div className="p-3">
                  <div className="first">
                    <div className="inner-group">
                      <div className="customer-data d-flex align-items-center ">
                        <h6 className="m-0 font-weight-bold">{preferredLang === 'swe' ? 'Anställd:' : 'Employee:'}</h6>
                        <p className="m-0">{salarySlipData?.userId}</p>
                      </div>

                      {salarySlipData?.userBank && (
                        <div className="customer-data d-flex align-items-center">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Bankdetaljer' : 'Bank details'}
                            -&nbsp;
                          </h6>
                        </div>
                      )}

                      <div>
                        {bankDetails?.iban && (
                          <>
                            <div className="d-flex align-items-center">
                              <h6 className="m-0 ">{'IBAN'}:&nbsp;</h6>
                              <p className="m-0">{bankDetails?.iban}</p>
                            </div>
                            <div className="d-flex align-items-center">
                              <h6 className="m-0 ">{'BIC'}:&nbsp;</h6>
                              <p className="m-0">{bankDetails?.bic}</p>
                            </div>
                          </>
                        )}
                        {bankDetails?.account && (
                          <>
                            <div className="d-flex align-items-center">
                              <h6 className="m-0">
                                {preferredLang === 'swe' ? 'Kontonummer' : 'Account number'}
                                :&nbsp;
                              </h6>
                              <p className="m-0">{bankDetails?.account}</p>
                            </div>

                            <div className="d-flex align-items-center">
                              <h6 className="m-0">
                                {preferredLang === 'swe' ? 'Clearingnummer' : 'Clearing number'}
                                :&nbsp;
                              </h6>
                              <p className="m-0">{bankDetails?.clearing}</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" p-3">
                  <div className="second">
                    <div className="inner-group">
                      {salarySlipData?.salaryType !== 3 && (
                        <div className="customer-data d-flex align-items-center">
                          {salarySlipData?.salaryType === 2 ? (
                            <>
                              <h6 className="m-0 font-weight-bold">
                                {preferredLang === 'swe' ? 'Semesterersättnings-ID' : 'Holiday Payout Id:'}
                              </h6>
                              <p className="m-0">{salarySlipData?.holidayPayoutId}</p>
                            </>
                          ) : (
                            <>
                              <h6 className="m-0 font-weight-bold">
                                {preferredLang === 'swe' ? 'Faktura-ID:' : 'Invoice Id:'}
                              </h6>
                              <p className="m-0">{salarySlipData?.invoiceId}</p>
                            </>
                          )}
                        </div>
                      )}

                      <div className="customer-data d-flex align-items-center">
                        <h6 className="m-0 font-weight-bold">
                          {preferredLang === 'swe' ? 'Betalningsdatum:' : 'Payment Date:'}
                        </h6>
                        <p className="m-0">{formatDate2(salarySlipData?.paidDate)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="salary-table table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">{preferredLang === 'swe' ? 'Nam' : 'Name'}</th>
                      <th scope="col">{preferredLang === 'swe' ? 'Från datum' : 'From Date'}</th>
                      <th scope="col">{preferredLang === 'swe' ? 'Till datum' : 'To Date'}</th>
                      <th scope="col">{preferredLang === 'swe' ? 'Enhetsmått' : 'Quantity Unit'}</th>
                      <th scope="col">{preferredLang === 'swe' ? 'Belopp' : 'Amount'}</th>
                      <th scope="col">{preferredLang === 'swe' ? 'Total' : 'Total'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salarySlipData?.employeeSalaryHours?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {contentMap[item?.description]?.[preferredLang]}

                          <div>{item?.articleNumber}</div>
                        </td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.00</td>
                        <td>{item?.amount}</td>
                        <td>{item?.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="salary-slip-calculation">
                <h5 className="font-weight-bold h5">
                  {preferredLang === 'swe' ? 'Löneberäkning:' : 'Salary calculation:'}
                </h5>
                <div>
                  <div className="deductionDetails" style={{ alignItems: 'center' }}>
                    {salarySlipData?.salaryType === 2 ? (
                      <>
                        <p className="mb-1 h6" style={{ fontSize: '15px' }}>
                          {preferredLang === 'swe' ? 'Semesterlönsbelopp' : 'Holiday Pay Amount'}
                        </p>
                        <p className="m-0 ml-auto" style={{ fontSize: '15px' }}>
                          SEK {salarySlipData?.holidayPayoutTotalAmountRequested}
                        </p>
                      </>
                    ) : (
                      salarySlipData?.salaryType !== 3 && (
                        <>
                          <p className="mb-1 h6" style={{ fontSize: '15px' }}>
                            {preferredLang === 'swe' ? 'Faktura belopp exkl. moms' : 'Invoice amount excl. VAT'}
                          </p>
                          <p className="m-0 ml-auto" style={{ fontSize: '15px' }}>
                            SEK {salarySlipData.freelancerInvoiceAmount}
                          </p>
                        </>
                      )
                    )}
                  </div>
                  <div className="deductionDetails bg-highlight" style={{ alignItems: 'center' }}>
                    {salarySlipData?.salaryType === 2 ? (
                      <>
                        <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                          {preferredLang === 'swe' ? 'Administratör Avgift' : 'Administrator Fee'}
                        </p>
                        <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                          SEK {salarySlipData?.holidayPayoutAdministratorFee}
                        </p>
                      </>
                    ) : (
                      salarySlipData?.salaryType !== 3 && (
                        <>
                          <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                            {preferredLang === 'swe' ? 'Invozio avgift' : 'Invozio percent fee'}
                          </p>
                          <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                            SEK {salarySlipData?.fakturaFee}
                          </p>
                        </>
                      )
                    )}
                  </div>
                  {salarySlipData?.salaryType !== 2 && salarySlipData?.salaryType !== 3 && (
                    <>
                      <div className="deductionDetails" style={{ alignItems: 'center' }}>
                        <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                          {preferredLang === 'swe' ? 'Express Avgift' : 'Express Fee'}
                        </p>
                        <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                          SEK {salarySlipData?.expressFeeAmount}
                        </p>
                      </div>
                      <div className="deductionDetails" style={{ alignItems: 'center' }}>
                        <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                          {preferredLang === 'swe' ? 'Semesterersättning' : 'Holiday pay'}
                        </p>
                        <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                          SEK {salarySlipData?.holidaySavingsAmount}
                        </p>
                      </div>
                      <div className="deductionDetails" style={{ alignItems: 'center' }}>
                        <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                          {preferredLang === 'swe' ? 'Social Avgift' : 'Social Fee'}
                        </p>
                        <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                          SEK {salarySlipData?.totalSocialFee}
                        </p>
                      </div>
                    </>
                  )}

                  <div className="deductionDetails" style={{ alignItems: 'center' }}>
                    <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                      {salarySlipData?.salaryType === 2
                        ? preferredLang === 'swe'
                          ? 'Bruttolön'
                          : 'Gross salary'
                        : preferredLang === 'swe'
                        ? 'Bruttolön inkl. semesterlön'
                        : 'Gross salary incl. holiday pay'}
                    </p>
                    <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                      SEK {salarySlipData?.grossSalary}
                    </p>
                  </div>
                  <div className="deductionDetails" style={{ alignItems: 'center' }}>
                    <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                      {preferredLang === 'swe' ? 'Skatt' : 'Tax'}
                    </p>
                    <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                      SEK {salarySlipData?.tax}
                    </p>
                  </div>
                  <div className="deductionDetails" style={{ alignItems: 'center' }}>
                    <p className="mb-1 h6" style={{ fontSize: '14px' }}>
                      {preferredLang === 'swe' ? 'Nettolön inkl. semesterlön' : 'Net salary incl. holiday pay'}
                    </p>
                    <p className="m-0 ml-auto" style={{ fontSize: '14px' }}>
                      SEK {salarySlipData?.amount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-2 ">
                <div className="col salary-detail-one tax-section">
                  <div className="d-flex flex-wrap align-items-center justify-content-between mt-1 p-2 tax-popup">
                    <div className="first">
                      <div className="inner-group">
                        <div className="customer-data d-flex align-items-center mb-3 ">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Skatteavdrag:' : `Tax deduction:`}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.invoice?.tax}</p>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3 ">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Förskott på arbetsgivaravgift:' : `Preliminary employer's fee:`}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.totalSocialFee}</p>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Engångsskattebas:' : 'One-time tax basis:'}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.grossSalary}</p>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Betald ledighet:' : 'Holidays Paid:'}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.holidaySavingsAmount}</p>
                        </div>
                      </div>
                    </div>
                    <div className="third">
                      <div className="inner-group">
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Bruttolön:' : 'Gross salary:'}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.grossSalary}</p>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Preliminär skatt:' : 'Preliminary tax:'}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.tax}</p>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Skattefria utgifter/avdrag:' : 'Tax-free expenses/deductions:'}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.totalTaxFreeArticlesAmount}</p>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Att betala:' : 'To pay:'}
                          </h6>
                          <p className="m-0 pl-3">{salarySlipData?.toPay}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-2">
                <div className="col salary-detail-two  ">
                  <div className="d-flex flex-wrap align-items-center justify-content-between mt-1 p-2 employee-detail">
                    <div className="first">
                      <div className="inner-group">
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">Address;</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">Invozio LV SIA</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">AHTRI 12</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">10151 Tallinn</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">Estonia</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">https://www.invozio.com</h6>
                        </div>
                      </div>
                    </div>
                    <div className="second">
                      <div className="inner-group">
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">Phone</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">010-494 77 11</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">{preferredLang === 'swe' ? 'E-post' : 'Email'}</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">info@invozio.com</h6>
                        </div>
                      </div>
                    </div>
                    <div className="second">
                      <div className="inner-group">
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Organisationsnummer' : 'Organization number'}
                          </h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">502085-4062</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0 font-weight-bold">
                            {preferredLang === 'swe' ? 'Momsnummer' : 'Vat Number'}
                          </h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">SE502085406201</h6>
                        </div>
                        <div className="customer-data d-flex align-items-center mb-3">
                          <h6 className="m-0">
                            {preferredLang === 'swe' ? 'Godkänd för F-skatt' : 'Approved for F-tax'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            onClick={() => {
              setDownloadModalOpen(!downloadModalOpen);
              setPreferredLang('swe');
            }}
            variant="contained"
            style={{ marginRight: '8px' }}
          >
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleCalendarDownloadPdf}>
            Download
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PaySlipModal;
