/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */
/* eslint-disable */

import React, { memo, useEffect, useRef, useState } from 'react';
import { Modal, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/commonFunc';
import Loader from '../../../components/Loader';
import { Alert, Button } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';

const SalaryDownloadPdfModal = ({
  toggleModal,
  settoggleModal,
  salarySelected,
  getSalariesData,
  selectedSalaryNestedTabValue,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [getNationalPdf, setgetNationalPdf] = useState(null);
  const [getInterNationalPdf, setgetInterNationalPdf] = useState(null);

  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);
  const [togglePdfModal, settogglePdfModal] = useState(false);
  const [modalContentType, setModalContentType] = useState('');
  const [isError, setisError] = useState(null);

  const [groupedPayments, setGroupedPayments] = useState({});
  const [interNationalGroupPayments, setInterNationalGroupPayments] = useState({});

  const moveCheckIntoPaid = async (downloadId) => {
    try {
      setLoading(true);
      const response = await axios.post('admin/adminsalary/pdf-send-to-paid', [...downloadId]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    setIsPDFDownloaded(true);
    const salaryIds = [];
    const collectSalaryIds = (payments) => {
      payments.forEach((payment) => {
        if (payment.salaryId) {
          salaryIds.push(payment.salaryId);
        }
      });
    };
    if (modalContentType === 'national') {
      Object.keys(groupedPayments).forEach((dateData) => {
        collectSalaryIds(groupedPayments[dateData]);
      });
    } else if (modalContentType === 'international') {
      Object.keys(interNationalGroupPayments).forEach((dateData) => {
        collectSalaryIds(interNationalGroupPayments[dateData]);
      });
    }
    const element = document.getElementById('downloadSalary_pdf');
    if (element) {
      const doc = new jsPDF('p', 'mm', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margins = 10;
      const contentWidth = pageWidth - 2 * margins;
      const canvas = await html2canvas(element, { scale: 2 });
      const imgWidth = contentWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const position = margins;
      let pageNum = 0;
      while (heightLeft > 0) {
        const pageImgHeight = Math.min(pageHeight - 2 * margins, heightLeft);
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = canvas.width;
        tempCanvas.height = (pageImgHeight / imgHeight) * canvas.height;
        tempCtx.drawImage(
          canvas,
          0,
          pageNum * (pageHeight - 2 * margins) * (canvas.height / imgHeight),
          canvas.width,
          tempCanvas.height,
          0,
          0,
          canvas.width,
          tempCanvas.height
        );
        const pageImgData = tempCanvas.toDataURL('image/jpeg', 1.0);
        if (pageNum > 0) {
          doc.addPage();
        }
        doc.addImage(pageImgData, 'JPEG', margins, position, imgWidth, pageImgHeight);
        heightLeft -= pageImgHeight;
        pageNum++;
      }
      doc.save('Salary-info.pdf');
    }
    setIsPDFDownloaded(false);
    settogglePdfModal(false);
    await moveCheckIntoPaid(salaryIds);
    await getPdfCountsData();
    await getSalariesData();
  };

  const getPdfCountsData = async () => {
    try {
      setLoading(true);
      let Url;
      if (selectedSalaryNestedTabValue === '2') {
        Url = '/admin/adminsalary/new-pdf';
      } else if (selectedSalaryNestedTabValue === '3') {
        Url = '/admin/adminsalary/regenerate-new-pdf';
      } else {
        setLoading(false);
        return;
      }
      const response = await axios.post(Url, [...salarySelected]);
      setgetNationalPdf(response?.data?.response?.nationalsalaries);
      setgetInterNationalPdf(response?.data?.response?.internationalsalaries);
      if (response?.data?.message !== 'Success') {
        setisError(response?.data?.message);
      } else {
        setisError(null);
      }
    } catch (err) {
      setisError(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (toggleModal) {
      getPdfCountsData();
    }
  }, [toggleModal, selectedSalaryNestedTabValue]);

  const todayDate = formatDate(new Date());

  useEffect(() => {
    if (getNationalPdf) {
      const paymentDates = getNationalPdf.map((p) => formatDate(p?.paymentDate));
      const uniquePaymentDates = [...new Set(paymentDates)];
      const groupedPayments = uniquePaymentDates.reduce((acc, date) => {
        acc[date] = getNationalPdf.filter((payment) => formatDate(payment.paymentDate) === date);
        return acc;
      }, {});
      setGroupedPayments(groupedPayments);
    }
    if (getInterNationalPdf) {
      const interNationalPayments = getInterNationalPdf.map((p) => formatDate(p?.paymentDate));
      const uniInternationalPayments = [...new Set(interNationalPayments)];

      const interNationalGroupPayments = uniInternationalPayments.reduce((acc, date) => {
        acc[date] = getInterNationalPdf.filter((payment) => formatDate(payment.paymentDate) === date);
        return acc;
      }, {});
      setInterNationalGroupPayments(interNationalGroupPayments);
    }
  }, [getNationalPdf, getInterNationalPdf]);

  const validationMessageRef = useRef();
  const modalContentRef = useRef();
  const setAddModalErrorMsg = () => {
    validationMessageRef.current?.scrollIntoView({ block: 'nearest' });
  };

  useEffect(() => {
    setAddModalErrorMsg();
  }, []);

  useEffect(() => {
    if (togglePdfModal && modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [togglePdfModal]);

  return (
    <React.Fragment>
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={toggleModal}
        animation={false}
        className="pdfDownloader fade.pdfDownloader.modal"
        style={{ marginTop: '65px' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Salary Details Counts')}</Modal.Title>
        </Modal.Header>
        {isError !== null && <Alert severity="error">{isError}</Alert>}
        {loading && <Loader />}
        <div className={`${loading ? '' : ''}`}></div>
        <div className="pdfcount_wrap">
          <p className="pl-3 mt-2">
            Salary for International bank: <span>{Object.keys(interNationalGroupPayments)?.length || 0}</span>
          </p>
          {Object.keys(interNationalGroupPayments)?.length > 0 && (
            <Button
              onClick={() => {
                setModalContentType('international');
                settogglePdfModal(true);
              }}
              sx={{ height: 30, marginTop: 1, marginLeft: 5, backgroundColor: '#62b5e514' }}
            >
              see generated details
            </Button>
          )}
        </div>
        <div className="pdfcount_wrap mt-2">
          <p className="pl-3">
            Salary for National bank : <span>{Object.keys(groupedPayments)?.length || 0}</span>
          </p>
          {Object.keys(groupedPayments)?.length > 0 && (
            <Button
              onClick={() => {
                setModalContentType('national');
                settogglePdfModal(true);
              }}
              sx={{ height: 30, marginLeft: 5, backgroundColor: '#62b5e514' }}
            >
              see generated details
            </Button>
          )}
        </div>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              settoggleModal(false);
              setisError(null);
              setInterNationalGroupPayments({});
              setGroupedPayments({});
            }}
          >
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={togglePdfModal}
        onHide={() => {
          settogglePdfModal(false);
        }}
        animation={false}
        className="pdfDownloader fade.pdfDownloader.modal"
        style={{ marginTop: '65px' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Salary Details')}</Modal.Title>
        </Modal.Header>

        <div ref={modalContentRef}>
          <div>
            {modalContentType === 'national' && (
              <div id="downloadSalary_pdf">
                {Object.keys(groupedPayments).map((dateData, idx) => {
                  const payments = groupedPayments[dateData];
                  const totalToPay = payments?.reduce((sum, item) => sum + item?.toPay, 0);
                  return (
                    <div key={idx} className="mt-3 p-2">
                      <div className="ml-2 mr-2 border border-dark p-2">
                        <Row>
                          <Col lg={12} md={12} sm={12}>
                            <h3>{'Banklista'}</h3>
                          </Col>
                        </Row>
                      </div>

                      <div className="ml-2 mt-2 mr-2 border border-dark p-2">
                        <Row>
                          <InputGroup>
                            <Col className="first_remove" lg={6} md={6} sm={12}>
                              <p>{'Utbetalningsdatum'}</p>
                              <p>{dateData}</p>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <p>{'Kontonummer'}</p>
                              <p>{'5984-7376'}</p>
                            </Col>
                          </InputGroup>
                        </Row>
                      </div>

                      <div className="ml-2 mt-2 mr-2 border border-dark p-2">
                        <div className="border-dark">
                          <Row>
                            <Col className="first_remove" lg={1} md={1} sm={12}>
                              <p>{'Anstnr'}</p>
                            </Col>
                            <Col className="first_remove" lg={3} md={3} sm={12}>
                              <p>{'Anställds namn'}</p>
                            </Col>
                            <Col className="first_remove" lg={3} md={3} sm={12}>
                              <p>{'Personnr'}</p>
                            </Col>
                            <Col className="first_remove" lg={3} md={3} sm={12}>
                              <p>{'Kontonummer'}</p>
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <p>{'Att utbetala'}</p>
                            </Col>
                          </Row>
                        </div>
                        <hr className="downloadpdf_line" />
                        <div className="border-dark">
                          {payments?.map((d, index) => (
                            <Row key={index}>
                              <Col className="first_remove" lg={1} md={1} sm={12}>
                                <p>{d?.employeeId}</p>
                              </Col>
                              <Col className="first_remove" lg={3} md={3} sm={12}>
                                <p>{d?.employeeName}</p>
                              </Col>
                              <Col className="first_remove" lg={3} md={3} sm={12}>
                                <p>{d?.ssn}</p>
                              </Col>
                              <Col className="first_remove" lg={3} md={3} sm={12}>
                                <p>{d?.accountNumber}</p>
                              </Col>
                              <Col lg={2} md={2} sm={12}>
                                <p>{d?.toPay}</p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                        <hr className="downloadpdf_line" />
                        <div>
                          <Row>
                            <Col lg={12} md={12} sm={12}>
                              <p className="text-right">{'Totalt att utbetala'}</p>
                              <p className="text-right">{totalToPay?.toFixed(2)}</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="ml-2 mt-2 mr-2 border border-dark p-2">
                        <Row>
                          <Col className="first_remove" lg={3} md={3} sm={12}>
                            <p>{'Invozio LV SIA'}</p>
                          </Col>

                          <Col className="first_remove" lg={4} md={4} sm={12}>
                            <p>{'Telefon'}</p>
                            <p>{'010-494 77 11'}</p>
                          </Col>

                          <Col className="first_remove" lg={3} md={3} sm={12}>
                            <p>{'Utskriftstillfälle'}</p>
                            <p>{todayDate}</p>
                          </Col>

                          <Col lg={1} md={1} sm={12}>
                            <p>{'Sign'}</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {modalContentType === 'international' && (
              <div id="downloadSalary_pdf">
                {Object.keys(interNationalGroupPayments).map((dateData, idx) => {
                  const payments = interNationalGroupPayments[dateData];
                  const totalToPay = payments?.reduce((sum, item) => sum + item?.toPay, 0);
                  return (
                    <div key={idx} className="mt-3 p-2">
                      <div className="ml-2 mr-2 border border-dark p-2">
                        <Row>
                          <Col lg={12} md={12} sm={12}>
                            <h3>{'Banklista'}</h3>
                          </Col>
                        </Row>
                      </div>

                      <div className="ml-2 mt-2 mr-2 border border-dark p-2">
                        <Row>
                          <InputGroup>
                            <Col className="first_remove" lg={6} md={6} sm={12}>
                              <p>{'Utbetalningsdatum'}</p>
                              <p>{dateData}</p>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <p>{'Kontonummer'}</p>
                              <p>{'5984-7376'}</p>
                            </Col>
                          </InputGroup>
                        </Row>
                      </div>

                      <div className="ml-2 mt-2 mr-2 border border-dark p-2">
                        <div className="border-dark">
                          <Row>
                            <Col className="first_remove" lg={1} md={1} sm={12}>
                              <p>{'Anstnr'}</p>
                            </Col>
                            <Col className="first_remove" lg={3} md={3} sm={12}>
                              <p>{'Anställds namn'}</p>
                            </Col>
                            <Col className="first_remove" lg={3} md={3} sm={12}>
                              <p>{'Personnr'}</p>
                            </Col>
                            <Col className="first_remove" lg={3} md={3} sm={12}>
                              <p>{'Kontonummer'}</p>
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <p>{'Att utbetala'}</p>
                            </Col>
                          </Row>
                        </div>
                        <hr className="downloadpdf_line" />
                        <div className="border-dark">
                          {payments?.map((d, index) => (
                            <Row key={index}>
                              <Col className="first_remove" lg={1} md={1} sm={12}>
                                <p>{d?.employeeId}</p>
                              </Col>
                              <Col className="first_remove" lg={3} md={3} sm={12}>
                                <p>{d?.employeeName}</p>
                              </Col>
                              <Col className="first_remove" lg={3} md={3} sm={12}>
                                <p>{d?.ssn}</p>
                              </Col>
                              <Col className="first_remove" lg={3} md={3} sm={12}>
                                <p>{d?.accountNumber}</p>
                              </Col>
                              <Col lg={2} md={2} sm={12}>
                                <p>{d?.toPay}</p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                        <hr className="downloadpdf_line" />
                        <div>
                          <Row>
                            <Col lg={12} md={12} sm={12}>
                              <p className="text-right">{'Totalt att utbetala'}</p>
                              <p className="text-right">{totalToPay?.toFixed(2)}</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="ml-2 mt-2 mr-2 border border-dark p-2">
                        <Row>
                          <Col className="first_remove" lg={3} md={3} sm={12}>
                            <p>{'Invozio LV SIA'}</p>
                          </Col>

                          <Col className="first_remove" lg={4} md={4} sm={12}>
                            <p>{'Telefon'}</p>
                            <p>{'010-494 77 11'}</p>
                          </Col>

                          <Col className="first_remove" lg={3} md={3} sm={12}>
                            <p>{'Utskriftstillfälle'}</p>
                            <p>{todayDate}</p>
                          </Col>

                          <Col lg={1} md={1} sm={12}>
                            <p>{'Sign'}</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              settogglePdfModal(false);
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleDownloadPDF}
            sx={{ float: 'right', backgroundColor: '#62b5e514' }}
            autoFocus
            disabled={isPDFDownloaded === true}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default memo(SalaryDownloadPdfModal);
