/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable new-cap */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable spaced-comment */
import * as Yup from 'yup';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'axios';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { toast } from 'react-toastify';
// material
import {
  Card,
  Table,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Tooltip,
  Box,
  Modal,
  Stack,
  IconButton,
  ButtonGroup,
} from '@mui/material';
// components
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HistoryIcon from '@mui/icons-material/History';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  certificateDropOptions,
  dataTable,
  getModalBoxStyle,
  taxIncomeService,
  validate,
  validateSpecificDates,
} from '../../utils/commonFunc';
import { ListHead, ListToolbar } from '../../sections/@dashboard/table';
import ApplyEmployeeCertificateForm from './apply-employee-certificate-form';
import Loader from '../../components/Loader';
import Iconify from '../../components/Iconify';
import UsersTable from './salaries/calendar/UsersTable';
import { ViewUsers } from './salaries/calendar/ViewUsers';
import PdfTable from './pdfFilter';
import { Locales } from '../../utils/constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'certificateId', label: 'Certificate No', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'userId', label: 'User Id', alignRight: false },
  { id: 'createdDate', label: 'Created At', alignRight: false },
  { id: 'emailAddress', label: 'Email Address', alignRight: false },
  { id: 'certificateStatus', label: 'Certificate Status', alignRight: false },
  { id: 'phoneNo', label: 'Upload Certificate', alignRight: false },
  { id: '', label: 'Actions' },
];

const usersAndHolidayTab = [
  {
    value: '1',
    tab: 2,
  },
  {
    value: '2',
    tab: 3,
  },
  {
    value: '3',
    tab: 1,
  },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

function applySortFilter(array, comparator, query, orgArray) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const searchRegex = new RegExp(escapeRegExp(query), 'i');
    return filter(orgArray, (_row) =>
      Object.keys(_row).some((field) => {
        if (_row[field]) return searchRegex.test(_row[field].toString());
        return false;
      })
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function EmployerCertificate() {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [selectedCertificateId] = useState(0);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState('2');
  const { userId } = useParams();
  const [tabs, settabs] = useState('1');
  const [userClicked, setUserClicked] = useState(false);
  const [isDownlaoding, setisDownlaoding] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [selectedCalendarNestedTabValue, setSelectedCalendarNestedTabValue] = useState('1');

  const [hideCheckboxColumn, setHideCheckboxColumn] = useState('true');
  const [pdfUploadedData, setPdfUploadedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [originalCertificate, setoriginalCertificate] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [activateModal, setActivateModal] = useState({
    certificateId: null,
    show: false,
    isActive: 'false',
  });
  const [sendModal, setSendModal] = useState({
    certificateId: null,
    show: false,
    isActive: 'false',
  });

  const handlePdfDownload = async (locale, certificateId, firstName, lastName) => {
    try {
      const date = new Date().toISOString().split('T')[0];
      const URL = `/adminemployeecertificate/${certificateId}/download?locale=${locale}`;
      const response = await axios.get(URL, { responseType: 'blob' });
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = `Employer certificate ${firstName} ${lastName} ${date}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (err) {
      if(err?.response?.status === 404){
        toast.error(`Certificate with id ${certificateId} not found.`);
      }
      toast.error(err?.response?.data?.message);
    }
  };

  const [toggleCertificateModal, settoggleCertificateModal] = useState({
    certificateId: null,
    show: false,
    rowData: {},
  });
  const [searchDropValue, setSearchDropValue] = useState();

  const { search } = useLocation();
  const location = useLocation();

  const AnswerSchema = Yup.object().shape({
    answer: Yup.string().required('Answer is required'),
  });

  const defaultValues = {
    answer: '',
  };

  const methods = useForm({
    resolver: yupResolver(AnswerSchema),
    defaultValues,
  });

  const { reset } = methods;

  useEffect(() => {
    fetchQuestions();
  }, [page, rowsPerPage, selectedTabValue, tabs, searchQuery]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/adminemployeecertificate?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&active=${selectedTabValue}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/adminemployeecertificate?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&active=${selectedTabValue}&searchQuery=${searchQuery}`;
      }
      const res = await axios.get(URL);
      if (res?.status === 200) {
        setLoading(false);
        setoriginalCertificate(res.data.response);

        const filteredRows = applySortFilter(
          res.data.response,
          getComparator(order, orderBy),
          searchQuery,
          originalCertificate
        );
        selectAllClick(isAllSelected, filteredRows);
        setQuestions(filteredRows);
        setTotalCount(res.data.totalCount);
        if (selectedTabValue === '1') {
          setHideCheckboxColumn('true');
          setSelectedTabValue('1');
          setFilteredQuestions(filteredRows);
        } else if (selectedTabValue === '2') {
          setHideCheckboxColumn('true');
          setSelectedTabValue('2');
          const cartificateId = new URLSearchParams(search).get('notificationVal');

          const notificationFilteredList = filteredRows?.map((item) => ({
            ...item,
            isSelected: item.certificateId === Number(cartificateId),
          }));

          selectAllClick(isAllSelected, notificationFilteredList);
          setFilteredQuestions(notificationFilteredList.filter((f) => f.isActive));
        } else if (selectedTabValue === '3') {
          setHideCheckboxColumn(false);
          setSelectedTabValue('3');
          setFilteredQuestions(filteredRows.filter((f) => !f.isActive));
        }
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        fetchQuestions();
        setLoading(true);
      } else {
        setQuestions([]);
        setLoading(false);
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      filteredQuestions,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      originalCertificate
    );
    setFilteredQuestions(filteredRows);
  };

  const selectAllClick = (isSelected, filteredQuestions) => {
    if (isSelected) {
      const newSelecteds = filteredQuestions?.map((n) => n.certificateId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, filteredQuestions);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      const filteredRows = applySortFilter(
        filteredQuestions,
        getComparator(order, orderBy),
        event.target.value,
        originalCertificate
      );
      setFilteredQuestions(filteredRows);
    } else {
      setFilteredQuestions(originalCertificate);
    }
  };

  const deleteEmployerCertificate = async (id) => {
    try {
      handleDialogClose();
      setLoading(true);
      await axios.delete(`/AdminEmployeeCertificate/${id}`).then(() => {
        setAlertMessage(`CertificateId #${id} has been deleted successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
      });
      await fetchQuestions();
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    await deleteQuestions();
  };

  const deleteQuestions = async () => {
    try {
      handleDialogClose();
      setLoading(true);
      await axios.delete(`/admin/AdminQA`).then((res) => {
        const isDeletedCount = res.data.response.isDeleted;
        if (isDeletedCount > 0) {
          setAlertMessage(
            `${
              isDeletedCount === res.data.totalCount
                ? `Selected ${isDeletedCount > 1 ? 'questions' : 'question'} has been deleted successfully.`
                : `${isDeletedCount} ${isDeletedCount > 1 ? 'questions' : 'question'} out of ${
                    res.data.totalCount
                  } has been deleted successfully.`
            }`
          );
          setAlertStatus('success');
        } else {
          setAlertMessage(`Selected ${isDeletedCount > 1 ? 'questions' : 'question'} can't been deleted.`);
          setAlertStatus('error');
        }
        setIsSnackBarOpen(true);
        fetchQuestions();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
      setSelected([]);
    }
  };

  const activateOrDeactivateQuestion = async (certificateId, type) => {
    try {
      setLoading(true);
      await axios.put(`/adminemployeecertificate/${certificateId}?isActive=${type}`).then(async () => {
        setAlertMessage(
          `CertificateId #${certificateId} has been ${type === 'true' ? 'Activated' : 'Deactivated'} successfully.`
        );
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        await fetchQuestions();
        setActivateModal({ show: false });
      });
    } catch (err) {
      setActivateModal({ show: false });
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setActivateModal({ show: false });
      setLoading(false);
    }
  };

  const isNotFound = filteredQuestions.length === 0;

  const handleDialogClose = () => {
    setIsOpenDialog(false);
  };

  // const handleDialogOpen = (freqQuestionToken) => {
  //   setSelectedCertificateId(freqQuestionToken);
  //   setIsOpenDialog(true);
  // };

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleTabChange = (e, newValue) => {
    setSearchQuery('');
    setPage(0);
    setSelected([]);
    setSelectedTabValue(newValue);
    setAllSelected(false);
    if (newValue === '1') {
      setHideCheckboxColumn('true');
      setFilteredQuestions(questions);
    } else if (newValue === '2') {
      setHideCheckboxColumn('true');
      setFilteredQuestions(questions.filter((f) => f.isActive));
    } else if (newValue === '3') {
      setHideCheckboxColumn(false);
      setFilteredQuestions(questions.filter((f) => !f.isActive));
    }
  };

  const handleChangeUpload = (e, certificateId) => {
    const file = e.target.files[0];
    if (file) {
      const { name } = file;
      const filtered = filteredQuestions.find((item) => item.certificateId === certificateId);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPdfUploadedData((prevState) => [...prevState, { ...filtered, fileName: name, fileContent: reader.result }]);
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    }
  };

  // ***************************************************************************************************************
  //********************************// Apply Employee Employee Certificate Start**************///////////////////////

  const [fieldsError, setfieldsError] = useState(false);
  const [checkList, setcheckList] = useState({
    isStillEmployed: false,
    isFullTimehoursPerWeek: false,
    isPartTimehoursPerWeek: false,
    isVaryingHours: false,
    employeeOwnRequest: false,
    isOtherReason: false,
    isContinueWorkFrom: false,
    isUntilFurtherNotice: false,
    isContinueVariableWorkingHours: false,
    isContinueEmployeeAccepted: false,
    isTimeWorkedUntilFurtherNotice: false,
    isTeachingHours: false,
    isMonthlySalary: false,
    isWeeklySalary: false,
    isDalilySalary: false,
    isHourlySalary: false,
    isFillingWorkvaried: false,
    isSalaryInAdditional: false,
    isEmployeeWithHoliday: false,
    isEmployeeWithLivingWage: false,
    isCrossInstedofTick: false,
    isTickInCorrectly: false,
    specialInfoEmployment: false,
    isCompensationTermination: false,
    isDigitally: false,
  });

  const [fields, setFields] = useState({
    // 1st section
    firstName: '',
    lastName: '',
    socialSecurityNumber: '',

    // 2nd section
    employmentPeriodFrom: '',
    employmentPeriodEnd: null,
    employeePosition: 'Freelancer',
    leaveAbsenceFrom: null,
    leaveAbsenceEnd: null,
    extendsIn: null,

    // 4th section
    fullTimehoursPerWeek: null,
    partTimehoursPerWeek: null,
    percentageFullTimeEmployement: null,

    // 6th section
    otherReason: null,

    // 8th section
    continueWorkFromDate: null,
    continueWorkEndDate: null,
    isContinueEmployeeAcceptedDate: null,

    // 9th section
    signature: null,
    nameClarification: null,

    // 11th
    reportedTimeFromDate: '',
    reportedTimeEndDate: '',
    teachingHours: '',

    // 12th
    salaryRefers: '',
    amountForOverTime: null,
    overTimeHours: null,
    hoursMertidFilllingTime: null,

    // 13th
    numberOfPaidHoliday: null,
    holidayPayInKroner: null,
    earnedLivingWage: null,

    // 14th
    otherInformation: null,

    // 15th
    employersDate: null,

    // 16th

    // 17th Person Info.
    userName: '',
    employerName: 'Invozio LV SIA',
    streetAddress: 'AHTRI 12',
    mailingAddress: '10151 Tallinn',
    organizationNumber: '5020854062',
    telephoneNumber: '010-4947711',

    // other Info in Text Area.
    otherInfomationEmployment: null,

    // employer Signature
    representativeCity: null,
  });

  const [firstYearDataTable, setfirstYearDataTable] = useState(dataTable());
  const [secondYearDataTable, setsecondYearDataTable] = useState(dataTable());
  const [incomeServiceFields, setincomeServiceFields] = useState(taxIncomeService);

  const updateCreatedDate = (startDate) => {
    const startYear = new Date(startDate).getFullYear();
    const updatedMonths = firstYearDataTable?.map((month) => ({
      ...month,
      hoursDate: `${startYear}-${month.month}-01`,
    }));
    setfirstYearDataTable(updatedMonths);
  };

  const updateCreatedDate1 = (startDate) => {
    const startYear = new Date(startDate).getFullYear();
    const updatedMonths = secondYearDataTable?.map((month) => ({
      ...month,
      hoursDate: `${startYear}-${month.month}-01`,
    }));
    setsecondYearDataTable(updatedMonths);
  };

  useEffect(() => {
    if (fields?.reportedTimeEndDate) {
      updateCreatedDate1(fields?.reportedTimeEndDate);
    }
  }, [fields?.reportedTimeEndDate]);

  useEffect(() => {
    if (fields?.reportedTimeFromDate) {
      updateCreatedDate(fields?.reportedTimeFromDate);
    }
  }, [fields?.reportedTimeFromDate]);

  useEffect(() => {
    if (!checkList?.isFillingWorkvaried) {
      setFields((prevFields) => ({
        ...prevFields,
        overTimeHours: null,
        hoursMertidFilllingTime: null,
      }));
    } else {
      setFields((prevFields) => ({
        ...prevFields,
        overTimeHours: prevFields?.overTimeHours || '',
        hoursMertidFilllingTime: prevFields?.hoursMertidFilllingTime || '',
      }));
    }
  }, [checkList?.isFillingWorkvaried]);

  useEffect(() => {
    if (checkList?.isEmployeeWithHoliday) {
      setFields((prevFields) => ({
        ...prevFields,
        numberOfPaidHoliday: prevFields?.numberOfPaidHoliday || '',
        holidayPayInKroner: prevFields?.holidayPayInKroner || '',
      }));
    } else {
      setFields((prevFields) => ({
        ...prevFields,
        numberOfPaidHoliday: null,
        holidayPayInKroner: null,
      }));
    }
  }, [checkList?.isEmployeeWithHoliday]);

  useEffect(() => {
    if (checkList?.isEmployeeWithLivingWage) {
      setFields((prevFields) => ({
        ...prevFields,
        earnedLivingWage: prevFields?.earnedLivingWage || '',
      }));
    } else {
      setFields((prevFields) => ({
        ...prevFields,
        earnedLivingWage: null,
      }));
    }
  }, [checkList?.isEmployeeWithLivingWage]);

  const combinedArray = [...firstYearDataTable, ...secondYearDataTable];
  const tableFilterData = combinedArray.filter((item) => {
    const hoursworked = parseFloat(item.hoursworked) || 0;
    const absence = parseFloat(item.absence) || 0;
    const overtime = parseFloat(item.overtime) || 0;
    const mertidFillingTime = parseFloat(item.mertidFillingTime) || 0;
    const allZero = hoursworked === 0 && absence === 0 && overtime === 0 && mertidFillingTime === 0;
    return !allZero;
  });

  const TaxserviceTableFiltered = incomeServiceFields.filter((item) => {
    const numberOfDaysWorked = parseFloat(item.numberOfDaysWorked) || 0;
    const dutyHours = parseFloat(item.dutyHours) || 0;
    const nOKMonth = parseFloat(item.nOKMonth) || 0;
    const nOKMonthAmount = parseFloat(item.nOKMonthAmount) || 0;

    const allZero = numberOfDaysWorked === 0 && dutyHours === 0 && nOKMonth === 0 && nOKMonthAmount === 0;

    return !allZero;
  });

  const filteredData = tableFilterData?.map((item) => {
    const newItem = {};
    for (const key in item) {
      if (key !== 'id') {
        if (key === 'month') {
          newItem[key] = item[key];
        } else {
          newItem[key] = isNaN(Number(item[key])) ? item[key] : Number(item[key]);
        }
      }
    }
    return newItem;
  });

  const filterTaxServiceIncome = TaxserviceTableFiltered?.map((item) => {
    const newItem = {};
    for (const key in item) {
      if (key === 'monthdate') {
        newItem[key] = item[key];
      } else if (key !== 'id') {
        newItem[key] = isNaN(Number(item[key])) ? item[key] : Number(item[key]);
      }
    }
    return newItem;
  });

  const handlefirstYearDataTableChange = (index, field, value) => {
    setfirstYearDataTable((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [field]: value,
      };
      const totalTeachingHoursFirstYear = updatedDetails.reduce(
        (acc, item) =>
          acc +
          (parseFloat(item.hoursworked) || 0) +
          (parseFloat(item.absence) || 0) +
          (parseFloat(item.overtime) || 0) +
          (parseFloat(item.mertidFillingTime) || 0),
        0
      );

      const totalTeachingHoursSecondYear = secondYearDataTable.reduce(
        (acc, item) =>
          acc +
          (parseFloat(item.hoursworked) || 0) +
          (parseFloat(item.absence) || 0) +
          (parseFloat(item.overtime) || 0) +
          (parseFloat(item.mertidFillingTime) || 0),
        0
      );

      const totalTeachingHours = totalTeachingHoursFirstYear + totalTeachingHoursSecondYear;

      setFields((prevFields) => ({
        ...prevFields,
        teachingHours: totalTeachingHours,
      }));
      return updatedDetails;
    });
  };

  const handleSecondYearDataTableChange = (index, field, value) => {
    setsecondYearDataTable((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [field]: value,
      };
      const totalTeachingHoursFirstYear = firstYearDataTable.reduce(
        (acc, item) =>
          acc +
          (parseFloat(item.hoursworked) || 0) +
          (parseFloat(item.absence) || 0) +
          (parseFloat(item.overtime) || 0) +
          (parseFloat(item.mertidFillingTime) || 0),
        0
      );
      const totalTeachingHoursSecondYear = updatedDetails.reduce(
        (acc, item) =>
          acc +
          (parseFloat(item.hoursworked) || 0) +
          (parseFloat(item.absence) || 0) +
          (parseFloat(item.overtime) || 0) +
          (parseFloat(item.mertidFillingTime) || 0),
        0
      );

      const totalTeachingHours = totalTeachingHoursFirstYear + totalTeachingHoursSecondYear;

      setFields((prevFields) => ({
        ...prevFields,
        teachingHours: totalTeachingHours,
      }));
      return updatedDetails;
    });
  };

  const handleIncomeService = (index, field, value) => {
    setincomeServiceFields((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [field]: value,
      };
      return updatedDetails;
    });
  };

  const handleChange = (name) => (evt) => {
    const { value, type, checked } = evt.target;
    if (
      name === 'employmentPeriodFrom' ||
      name === 'employmentPeriodEnd' ||
      name === 'leaveAbsenceFrom' ||
      name === 'leaveAbsenceEnd'
    ) {
      const startDate = new Date(name === 'employmentPeriodFrom' ? value : fields.employmentPeriodFrom);
      const endDate = new Date(name === 'employmentPeriodEnd' ? value : fields.employmentPeriodEnd);
      const leaStartDate = new Date(name === 'leaveAbsenceFrom' ? value : fields.leaveAbsenceFrom);
      const leaEndDate = new Date(name === 'leaveAbsenceEnd' ? value : fields.leaveAbsenceEnd);

      if (
        (name === 'employmentPeriodFrom' || name === 'employmentPeriodEnd') &&
        fields.employmentPeriodFrom &&
        fields.employmentPeriodEnd &&
        startDate > endDate
      ) {
        return toast.error('End Date Should be Greater than Start Date in Details of employment!!');
      }
      if (
        (name === 'leaveAbsenceFrom' || name === 'leaveAbsenceEnd') &&
        fields.leaveAbsenceFrom &&
        fields.leaveAbsenceEnd &&
        leaStartDate > leaEndDate
      ) {
        return toast.error('End Date Should be Greater than Start Date in Details of employment!!');
      }
    }

    if (name === 'continueWorkFromDate' || name === 'continueWorkEndDate') {
      const contnueStartDate = new Date(name === 'continueWorkFromDate' ? value : fields.continueWorkFromDate);
      const contnueEndtDate = new Date(name === 'continueWorkEndDate' ? value : fields.continueWorkEndDate);
      if (fields?.continueWorkFromDate && fields?.continueWorkEndDate) {
        if (contnueStartDate > contnueEndtDate) {
          return toast.error('End Date Should be Greater than Start Date in Offer of continued work!!');
        }
      }
    }

    if (name === 'reportedTimeFromDate' || name === 'reportedTimeEndDate') {
      const tableStartDate = new Date(name === 'reportedTimeFromDate' ? value : fields.reportedTimeFromDate);
      const tableEndDate = new Date(name === 'reportedTimeEndDate' ? value : fields.reportedTimeEndDate);
      if (tableStartDate > tableEndDate) {
        return toast.error('End Date Should be Greater than Start Date!!');
      }
    }

    if (name === 'isFullTimehoursPerWeek') {
      setFields((pre) => ({
        ...pre,
        partTimehoursPerWeek: null,
        percentageFullTimeEmployement: null,
      }));
    }
    if (name === 'isVaryingHours') {
      setFields((pre) => ({
        ...pre,
        fullTimehoursPerWeek: null,
        partTimehoursPerWeek: null,
        percentageFullTimeEmployement: null,
      }));
    }

    if (name === 'isStillEmployed') {
      if (checked) {
        setFields((prevState) => ({
          ...prevState,
          employmentPeriodEnd: null,
          continueWorkFromDate: null,
          continueWorkEndDate: null,
          isContinueEmployeeAcceptedDate: null,
        }));
      } else {
        setFields((prevState) => ({
          ...prevState,
          employmentPeriodEnd: '',
        }));
      }
    } else if (name === 'isFullTimehoursPerWeek') {
      if (checked) {
        setFields((prevState) => ({
          ...prevState,
          fullTimehoursPerWeek: '',
        }));
        setcheckList((prevState) => ({
          ...prevState,
          isPartTimehoursPerWeek: false,
          isVaryingHours: false,
        }));
      } else {
        setFields((prevState) => ({
          ...prevState,
          fullTimehoursPerWeek: null,
        }));
      }
    } else if (name === 'isPartTimehoursPerWeek' && checked) {
      setFields((prevState) => ({
        ...prevState,
        partTimehoursPerWeek: '',
        percentageFullTimeEmployement: '',
        fullTimehoursPerWeek: null,
      }));
      setcheckList((prevState) => ({
        ...prevState,
        isVaryingHours: false,
        isFullTimehoursPerWeek: false,
      }));
    } else if (name === 'isPartTimehoursPerWeek') {
      setFields((prevState) => ({
        ...prevState,
        partTimehoursPerWeek: null,
        percentageFullTimeEmployement: null,
      }));
    } else if (name === 'isVaryingHours' && checked) {
      setcheckList((prevState) => ({
        ...prevState,
        isPartTimehoursPerWeek: false,
        isFullTimehoursPerWeek: false,
      }));
    } else if (name === 'employeeOwnRequest' && checked) {
      setFields((prevState) => ({
        ...prevState,
        otherReason: null,
      }));
      setcheckList((prevState) => ({
        ...prevState,
        isOtherReason: false,
      }));
    } else if (name === 'isOtherReason') {
      setcheckList((prevState) => ({
        ...prevState,
        employeeOwnRequest: false,
      }));
    } else if (name === 'isMonthlySalary' && checked) {
      setcheckList((prevState) => ({
        ...prevState,
        isWeeklySalary: false,
        isDalilySalary: false,
        isHourlySalary: false,
      }));
    } else if (name === 'isWeeklySalary' && checked) {
      setcheckList((prevState) => ({
        ...prevState,
        isMonthlySalary: false,
        isDalilySalary: false,
        isHourlySalary: false,
      }));
    } else if (name === 'isDalilySalary' && checked) {
      setcheckList((prevState) => ({
        ...prevState,
        isMonthlySalary: false,
        isWeeklySalary: false,
        isHourlySalary: false,
      }));
    } else if (name === 'isHourlySalary' && checked) {
      setcheckList((prevState) => ({
        ...prevState,
        isMonthlySalary: false,
        isWeeklySalary: false,
        isDalilySalary: false,
      }));
    } else if (
      name === 'isMonthlySalary' ||
      name === 'isWeeklySalary' ||
      name === 'isDalilySalary' ||
      name === 'isHourlySalary'
    ) {
      setcheckList((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }

    if (type === 'checkbox') {
      setcheckList((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      let updatedValue = value;
      if (type === 'number') {
        const parsedValue = parseFloat(value);
        updatedValue = isNaN(parsedValue) ? '' : parsedValue;
      }
      if (name === 'socialSecurityNumber') {
        if (value.length <= 12 || fields[name].length > value.length) {
          setFields((prevState) => ({
            ...prevState,
            [name]: value,
          }));
          setfieldsError((prev) => ({
            ...prev,
            [name + 'Error']: value.length !== 12,
          }));
        }
      } else {
        setFields((prevState) => ({
          ...prevState,
          [name]: updatedValue,
        }));
        setfieldsError((prev) => ({
          ...prev,
          [name + 'Error']: value !== '' ? false : true,
        }));
      }
    }
  };

  const checkMonthdate = (data) => {
    const hasEmptyMonthdate = data.some((item) => item.monthdate === '');
    if (hasEmptyMonthdate) {
      return 'Month Date Can not be Empty in Remuneration taxed';
    }
  };

  const handleEditCertificate = async () => {
    const getNonNullData = Object.fromEntries(Object.entries(fields).filter(([key, value]) => value !== null));
    let checkData = {};
    if (fields) {
      checkData = validate(fields);
    }
    const specificFields = {
      continueWorkFromDate: fields?.continueWorkFromDate,
      continueWorkEndDate: fields?.continueWorkEndDate,
      isContinueEmployeeAcceptedDate: fields?.isContinueEmployeeAcceptedDate,
    };
    if (!checkList?.isStillEmployed && checkList?.isContinueWorkFrom) {
      checkData = validateSpecificDates(specificFields);
    }

    setfieldsError(checkData);

    if (fields?.teachingHours === '') return toast.error('Please fill in the row data for the table');

    const hasError = Object.values(checkData).some((value) => value === true);
    if (hasError) {
      return toast.error('Form cannot be empty.');
    }

    if (fieldsError?.socialSecurityNumberError) {
      return toast.error('SSN number should be 12 digits.');
    }

    if (fields?.signature === null) return toast.error('Please Add Signature');
    if (fields?.nameClarification === null) return toast.error('Please Enter Name Clarification');

    if (fields?.telephoneNumber?.length < 7 || fields?.telephoneNumber?.length > 15) {
      toast.error('Phone number should be greater than 10 and less than 12!');
      return;
    }

    if (fields?.extendsIn > 100) {
      return toast.error('Extend Percentage should less than 100');
    }

    const hasNotMonthdate = checkMonthdate(filterTaxServiceIncome);
    if (hasNotMonthdate) return toast.error(hasNotMonthdate);

    try {
      setLoading(true);
      const result = await axios.post('/AdminEmployeeCertificate', {
        ...getNonNullData,
        ...checkList,
        certificateId: toggleCertificateModal?.certificateId,
        EmployeeWagesDetails: filteredData,
        CertificateDetailsPerMonth: filterTaxServiceIncome,
        individualInvoices: false,
        selectPeriod: true,
      });

      if (result.status === 200) {
        toast.success('The employer certificate has been updated successfully');
        fetchQuestions();
        settoggleCertificateModal({ show: false });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.title);
    } finally {
      reset();
      // setLoading(false);
    }
  };

  const handleNext = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      handleEditCertificate();
    }
  };

  const handleSend = async (certificateId) => {
    try {
      setLoading(true);
      const result = await axios.post(`/adminemployeecertificate/sent-certificate/${certificateId}?isSent=true`);
      if (result.status === 200) {
        toast.success('The employer certificate has been sent successfully');
      }
      fetchQuestions();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      reset();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toggleCertificateModal?.certificateId) {
      const { rowData } = toggleCertificateModal;
      const updatedFields = {
        ...Object.keys(fields).reduce((acc, key) => {
          const fieldValue = rowData[key];
          acc[key] = fieldValue;
          return acc;
        }, {}),
      };
      const dateFields = [
        'employmentPeriodFrom',
        'employmentPeriodEnd',
        'continueWorkFromDate',
        'continueWorkEndDate',
        'employersDate',
        'isContinueEmployeeAcceptedDate',
        'leaveAbsenceFrom',
        'leaveAbsenceEnd',
        'reportedTimeFromDate',
        'reportedTimeEndDate',
        'salaryRefers',
      ];
      dateFields.forEach((field) => {
        updatedFields[field] = rowData[field] ? rowData[field].split('T')[0] : '';
      });
      updatedFields.employmentPeriodEnd = rowData.employmentPeriodEnd
        ? rowData.employmentPeriodEnd.split('T')[0]
        : null;
      setFields(updatedFields);
    }
  }, [toggleCertificateModal?.certificateId]);

  useEffect(() => {
    if (toggleCertificateModal?.rowData) {
      const newCheckList = Object.keys(checkList).reduce((acc, key) => {
        if (key in toggleCertificateModal.rowData) {
          acc[key] = toggleCertificateModal.rowData[key];
        }
        return acc;
      }, {});
      setcheckList((prev) => ({ ...prev, ...newCheckList }));
    }
  }, [toggleCertificateModal?.rowData]);

  useEffect(() => {
    if (toggleCertificateModal?.rowData?.employeeWagesDetails) {
      const reportedTimeFromDate = new Date(toggleCertificateModal?.rowData?.reportedTimeFromDate);
      const reportedTimeEndDate = new Date(toggleCertificateModal?.rowData?.reportedTimeEndDate);

      const filterDate = toggleCertificateModal?.rowData?.employeeWagesDetails?.filter(
        (f) => new Date(f.hoursDate).getFullYear() === reportedTimeFromDate.getFullYear()
      );
      const prefillData = dataTable(filterDate);
      setfirstYearDataTable(prefillData);

      if (reportedTimeFromDate.getFullYear() < reportedTimeEndDate.getFullYear()) {
        const filterDate1 = toggleCertificateModal?.rowData?.employeeWagesDetails?.filter(
          (f) => new Date(f.hoursDate).getFullYear() === reportedTimeEndDate.getFullYear()
        );
        const prefillData1 = dataTable(filterDate1);
        setsecondYearDataTable(prefillData1);
      }
    }
  }, [toggleCertificateModal?.rowData?.employeeWagesDetails]);

  useEffect(() => {
    if (toggleCertificateModal?.rowData?.certificateDetailsPerMonth) {
      const updatedIncomeServiceFields = taxIncomeService.map((item, index) => {
        const certificateDetailsPerMonthItem = toggleCertificateModal?.rowData?.certificateDetailsPerMonth[index];
        if (certificateDetailsPerMonthItem) {
          return {
            ...certificateDetailsPerMonthItem,
            monthdate: certificateDetailsPerMonthItem.monthdate?.split('T')[0],
          };
        }
        return item;
      });
      const filledValues = updatedIncomeServiceFields.filter((item) => {
        const values = Object.values(item);
        return values.some((value) => value !== '');
      });
      const remainingCount = 13 - filledValues.length;
      const finalIncomeServiceFields = [
        ...filledValues,
        ...Array(remainingCount).fill({
          monthdate: '',
          numberOfDaysWorked: '',
          dutyHours: '',
          nokMonth: '',
          otherRemunerationEnterArt: '',
          nokMonthAmount: '',
        }),
      ];
      setincomeServiceFields(finalIncomeServiceFields);
    }
  }, [toggleCertificateModal?.rowData?.certificateDetailsPerMonth]);

  const renderQuestions = () => (
    <>
      <ListToolbar
        hideEmailButton={'true'}
        numSelected={selected.length}
        searchQuery={searchQuery}
        onFilter={handleFilter}
        onDelete={handleDelete}
        dropOptions={certificateDropOptions}
        searchDropValue={searchDropValue}
        setSearchDropValue={setSearchDropValue}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table className="employer-table">
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={questions.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              hideCheckboxColumnHeader={hideCheckboxColumn}
            />

            <TableBody>
              {filteredQuestions?.map((row) => {
                const {
                  certificateId,
                  userName,
                  createdDate,
                  emailAddress,
                  isActive,
                  userId,
                  isSentCertificate,
                  firstName,
                  lastName,
                  signature,
                  nameClarification,
                  employersDate,
                  representativeCity,
                } = row;
                
                const isItemSelected = selected.indexOf(certificateId) !== -1;
                return (
                  <TableRow
                    hover
                    key={certificateId}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    style={{
                      backgroundColor: row?.isSelected ? 'lightgrey' : '',
                      cursor: 'pointer',
                    }}
                  >
                    {!hideCheckboxColumn && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, certificateId)}
                          size="small"
                        />
                      </TableCell>
                    )}
                    <TableCell align="left" style={{ fontSize: '13px' }}>
                      {certificateId}
                    </TableCell>
                    {userName?.length >= 9 ? (
                      <Tooltip title={userName} arrow placement="top">
                        <TableCell align="left" style={{ fontSize: '13px' }}>
                          {userName?.substring(0, 9).concat('...')}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left" style={{ fontSize: '13px' }}>
                        {userName}
                      </TableCell>
                    )}

                    <TableCell align="left" style={{ fontSize: '13px' }}>
                      {userId}
                    </TableCell>

                    <TableCell align="left" style={{ fontSize: '12px' }}>
                      {new Date(createdDate).toISOString().split('T')[0]}
                    </TableCell>

                    {emailAddress?.length >= 9 ? (
                      <Tooltip title={emailAddress} arrow placement="top">
                        <TableCell align="left" style={{ fontSize: '13px' }}>
                          {emailAddress?.substring(0, 9).concat('...')}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left" style={{ fontSize: '13px' }}>
                        {emailAddress}
                      </TableCell>
                    )}
                    <TableCell align="left" style={{ fontSize: '13px' }}>
                      {isSentCertificate ? <span>Sent Successfully</span> : <span>Pending</span>}
                    </TableCell>

                    <TableCell align="left" style={{ fontSize: '13px' }}>
                      {/* <input
                          type="file"
                          name="employer-certificate"
                          id="pic"
                          accept="application/pdf"
                          style={{ width: 180 }}
                          onChange={(e) => handleChangeUpload(e, certificateId)}
                        /> */}
                      {row?.fileName && row?.fileContent ? (
                        <p>{row?.fileName}</p>
                      ) : (
                        <ButtonGroup>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              settoggleCertificateModal({
                                certificateId,
                                show: true,
                                rowData: row,
                              });
                            }}
                            style={{ fontSize: '12px', width: '150px', minWidth: '50px' }}
                          >
                            View Certificate
                          </Button>
                          <>
                            <Button
                              startIcon={<FileDownloadIcon />}
                              style={{ fontSize: '12px' }}
                              onClick={() => handlePdfDownload(Locales.EN_US, certificateId, firstName, lastName)}
                              disabled={!(signature && nameClarification && employersDate && representativeCity)}
                            >
                              EN
                            </Button>
                            <Button
                              startIcon={<FileDownloadIcon />}
                              style={{ fontSize: '12px' }}
                              onClick={() => handlePdfDownload(Locales.SV_SE, certificateId, firstName, lastName)}
                              disabled={!(signature && nameClarification && employersDate && representativeCity)}
                            >
                              SE
                            </Button>
                          </>
                        </ButtonGroup>
                      )}
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSendModal({
                            certificateId,
                            show: true,
                            isActive: isActive ? 'true' : 'false',
                          });
                        }}
                        // onClick={() => handleSend(certificateId)}
                        style={{ marginRight: '10px', fontSize: '12px', width: '50px', minWidth: '50px' }}
                        disabled={isSentCertificate}
                      >
                        {isSentCertificate ? 'Sent' : 'Send'}
                      </Button>
                      <Tooltip title={!isActive ? 'Activate' : 'Deactivate'}>
                        <Button
                          variant="contained"
                          color={isActive ? 'primary' : 'error'}
                          onClick={() => {
                            setActivateModal({
                              certificateId,
                              show: true,
                              isActive: isActive ? 'true' : 'false',
                            });
                          }}
                          // onClick={() => {
                          //   activateOrDeactivateQuestion(isActive, certificateId);
                          // }}
                          style={{ width: 94, fontSize: '12px' }}
                        >
                          {isActive ? 'Activated' : 'Deactivated'}
                        </Button>
                      </Tooltip>

                      <Tooltip title="View logs">
                        <Button
                          type="submit"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/dashboard/view-logs?type=certificate&id=${certificateId}`, {
                              state: {
                                type: 'name',
                                name: userName,
                              },
                            });
                          }}
                          style={{
                            width: 50,
                            minWidth: '50px',
                            fontSize: '12px',
                            padding: '6px',
                          }}
                        >
                          <HistoryIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}

              <Dialog
                open={isOpenDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this employee Certificate?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose}>No</Button>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteEmployerCertificate(selectedCertificateId);
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </TableBody>

            {/* EmployeeCertificate Start */}

            <ApplyEmployeeCertificateForm
              fields={fields}
              checkList={checkList}
              setcheckList={setcheckList}
              handleChange={handleChange}
              setFields={setFields}
              ToggleShow={toggleCertificateModal}
              Toggleclose={settoggleCertificateModal}
              submitForCertificate={handleNext}
              fieldsError={fieldsError}
              setfieldsError={setfieldsError}
              // First Table
              firstYearDataTable={firstYearDataTable}
              handlefirstYearDataTableChange={handlefirstYearDataTableChange}
              //Second Table
              handleSecondYearDataTableChange={handleSecondYearDataTableChange}
              secondYearDataTable={secondYearDataTable}
              // Income Service Table
              incomeServiceFields={incomeServiceFields}
              handleIncomeService={handleIncomeService}
              loading={loading}
            />
            {/* EmployeeCertificate End  */}

            <Modal
              open={activateModal.show}
              onClose={() => setActivateModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to {activateModal.isActive === 'true' ? 'deactivate' : 'activate'} this employee
                  certificate?
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setActivateModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      activateOrDeactivateQuestion(
                        activateModal.certificateId,
                        activateModal.isActive === 'true' ? 'false' : 'true'
                      );
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={sendModal.show}
              onClose={() => setSendModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to send ?
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setSendModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      handleSend(sendModal.certificateId);
                      setSendModal({ show: false });
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchQuery} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );

  const handleTabs = (event, newValue) => {
    settabs(newValue);
    setSearchQuery('');
  };

  const handleUserClick = (item) => {
    if (item?.userId) {
      navigate(`/dashboard/employer-certificate/view-user/${item?.userId}`, { state: item });
    }
  };

  const handleNestedTab = (e, newValue) => {
    setSelectedCalendarNestedTabValue(newValue);
  };

  useEffect(() => {
    if (userId) {
      setUserClicked(true);
    } else {
      setUserClicked(false);
    }
  }, [userId]);

  const handleCalendarDownloadPdf = async () => {
    setisDownlaoding(true);
    const element = document.getElementById('calendar_details');
    if (!element) {
      setisDownlaoding(false);
      return;
    }
    await document.fonts.ready;
    const canvas = await html2canvas(element, {
      scale: 3,
      useCORS: true,
      logging: true,
    });
    const imgData = canvas.toDataURL('image/png', 1.0);
    const imgWidth = 210;
    const pageHeight = 297;
    const { width, height } = canvas;
    const aspectRatio = height / width;
    const imgHeight = imgWidth * aspectRatio;
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    if (dataArray?.length > 0) {
      doc.addPage();
      PdfTable({ doc, dataArray, pageHeight });
    }

    doc.save('calendar_details.pdf');
    setisDownlaoding(false);
  };

  return (
    <Page title="Questions">
      {loading && <Loader />}

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={() => navigate(-1)}>
            <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
            Employee Certificate
          </Typography>
        </Stack>

        {tabs === '2' && location?.pathname?.includes('/dashboard/employer-certificate/view-user') && (
          <Button disabled={isDownlaoding} onClick={handleCalendarDownloadPdf} variant="contained">
            Download PDF
          </Button>
        )}
      </Stack>

      <TabContext value={tabs}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabs} aria-label="lab API tabs example">
            <Tab label="Employee Certificate" value="1" />
            <Tab label="Calendar" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isSnackBarOpen}
            autoHideDuration={3000}
            onClose={handleSnackBarClose}
          >
            <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
          <Card className="table-data">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={selectedTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} aria-label="lab API tabs example">
                    <Tab label="ACTIVE" value="2" />
                    <Tab label="INACTIVE" value="3" />
                    <Tab label="ALL" value="1" />
                  </TabList>
                </Box>
                <TabPanel value="1">{renderQuestions()}</TabPanel>
                <TabPanel value="2">{renderQuestions()}</TabPanel>
                <TabPanel value="3">{renderQuestions()}</TabPanel>
              </TabContext>
            </Box>
          </Card>
        </TabPanel>

        <TabPanel value="2" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
          <Card className="table-data">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={selectedCalendarNestedTabValue}>
                {userClicked ? (
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <ViewUsers setDataArray={setDataArray} />
                  </Box>
                ) : (
                  <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={(e, newValue) => handleNestedTab(e, newValue)}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Active" value="1" />
                        <Tab label="Inactive" value="2" />
                        <Tab label="All" value="3" />
                      </TabList>
                    </Box>
                    <ListToolbar searchQuery={searchQuery} onFilter={handleFilter} />
                    {usersAndHolidayTab.map((item) => (
                      <TabPanel value={item?.value} sx={{ padding: 0 }} key={item?.value}>
                        <UsersTable
                          tab={item?.tab}
                          setLoading={setLoading}
                          handleUserClick={handleUserClick}
                          searchQuery={searchQuery}
                          selectedCalendarNestedTabValue={selectedCalendarNestedTabValue}
                        />
                      </TabPanel>
                    ))}
                  </>
                )}
              </TabContext>
            </Box>
          </Card>
        </TabPanel>
      </TabContext>
    </Page>
  );
}
