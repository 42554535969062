/* eslint-disable */
import { useDispatch } from 'react-redux';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

// React Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import NoteIcon from '@mui/icons-material/Note';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import HistoryIcon from '@mui/icons-material/History';
import DownloadIcon from '@mui/icons-material/Download';

// material
import { Typography, InputLabel, FormControl, TableContainer, TablePagination, IconButton } from '@mui/material';
import { Snackbar, MenuItem, TableRow, TableBody, TableCell, TableHead, Container } from '@mui/material';
import { Box, Card, Table, Stack, Modal, Alert, Select, Button, Tooltip, Checkbox } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';

// Components
import downloadSalaryDetailsAsPdf, { convertHouseworkType, invoiceDropOptions } from '../../../utils/commonFunc';
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import TooltipWithCopy from '../../../components/TooltipWithCopy';
import SearchNotFound from '../../../components/SearchNotFound';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import ErrosAlert from '../customers/ErrosAlert';
import Loader from '../../../components/Loader';
import { costPerDayArticle, costPerMonthArticle, costAgreementArticle, costPerWeekArticle } from '../Article';
import { HourlyWages, otherCost, ExpenditureAccordingToTheAttachedPDF } from '../Article';
import { formatDate, formatDate2, getModalBoxStyle } from '../../../utils/commonFunc';
import '../../../layouts/dashboard/index/index.css';

// Redux slices
import { fetchGlobalData } from 'src/redux/globalData/globalDataSlice';
import { getInvoiceData } from '../../../redux/auth/authSlice';
import { invoiceCreatedUserDetails } from 'src/redux/globalData/userSlice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'invoiceType', label: 'Invoice Type', alignRight: false },
  { id: 'customerName', label: 'Customer', alignRight: false },
  { id: 'createdBy', label: 'User Id', alignRight: false },
  { id: 'userName', label: 'Username', alignRight: false },
  { id: 'createdDate', label: 'Created Date', alignRight: false },
  { id: 'dueDate', label: 'Due Date', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'invoiceStatus', label: 'Invoice Status', alignRight: false },
  { id: 'invoiceSalaryStatus', label: 'Salary Status', alignRight: false },
  { id: 'expressPayout', label: 'Express Payment', alignRight: false },
  { id: 'Salary', label: 'Salary', alignRight: false },
  { id: 'isActive', label: 'Actions', alignRight: false },
];

const WORKING_HOURS_TABLE_HEAD = [
  {
    id: 'workingDate',
    label: 'Date',
    alignRight: false,
    flex: 1,
    padding: '6px 16px',
  },
  {
    id: 'articleName',
    label: 'Article Name',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'number',
    label: 'Unit Number',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'amountPerUnit',
    label: 'Amount per unit',
    alignRight: false,
    padding: '6px 16px',
  },

  { id: 'totalAmount', label: 'SEK', alignRight: false, padding: '6px 16px' },

  {
    id: 'grossAmount',
    label: 'Gross Salary',
    alignRight: false,
    padding: '6px 16px',
  },
  {
    id: 'expressFee',
    label: 'Express Fee',
    alignRight: false,
    padding: '6px 16px',
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

export default function Invoices() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [invoices, setInvoices] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedRow, setSelectedRow] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [orderBy, setOrderBy] = useState('createdDate');
  const [freelancerName, setFreelancerName] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState('2');
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [originalInvoice, setoriginalInvoice] = useState([]);
  const [hideCheckboxColumn, setHideCheckboxColumn] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState({
    open: false,
    index: null,
  });

  const [activateModal, setActivateModal] = useState({
    id: null,
    show: false,
  });

  const [toggleIsActive, settoggleIsActive] = useState({
    id: null,
    show: false,
  });

  const [createAccountModal, setCreateAccountModal] = useState({
    id: null,
    show: false,
    type: '',
  });

  const [expressPaymenttModal, setExpressPaymentModal] = useState({
    id: null,
    show: false,
    type: false,
  });
  const [IstogglePaidUnpaid, setIstogglePaidUnpaid] = useState({
    id: null,
    show: false,
    type: '',
  });

  const [isChangeStatus, setisChangeStatus] = useState({
    id: null,
    show: false,
    type: '',
  });

  const [isAllSelected, setAllSelected] = useState(false);
  const [toggleNote, setToggleNote] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(false);
  const [notesContent, setNotesContent] = useState('');
  const [isNotesContentEmpty, setIsNotesContentEmpty] = useState(false);
  const [commentList, setcommentList] = useState([]);
  const [searchDropValue, setSearchDropValue] = useState();
  const [showSyncModal, setshowSyncModal] = useState(false);
  const [isError, setisError] = useState(false);
  const [messages, setMessages] = useState({
    customerUpdatedOnFortnox: null,
    customerUpdatedOnFortnoxError: null,
    customeraddedOnFortnox: null,
    customeraddedOnFortnoxError: null,
    invoiceCanceledOnFortnox: null,
    invoiceCanceledOnFortnoxError: null,
    invoiceFileuploadedOnFortnox: null,
    invoiceFileuploadedOnFortnoxError: null,
    invoiceTaxReductioncreatedOnFortnox: null,
    invoiceTaxReductioncreatedOnFortnoxError: null,
    invoiceUpdatedOnFortnox: null,
    invoiceUpdatedOnFortnoxError: null,
    invoiceaddedOnFortnox: null,
    invoiceaddedOnFortnoxError: null,
  });

  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const articlesNo = {
    HourlyWages: '92',
    otherCost: '93',
    ExpenditureAccordingToTheAttachedPDF: '2',
    costPerDay: '94',
    costPerWeek: '95',
    costPerMonth: '96',
    costAgreement: '97',
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchInvoices();
    }, 600);
    return () => clearTimeout(timeoutId);
  }, [page, rowsPerPage, selectedTabValue, searchQuery]);

  const fetchInvoices = async () => {
    try {
      const userId = new URLSearchParams(search).get('userId');
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/invoice?PageSize=${rowsPerPage}&PageNumber=${page + 1
          }&active=${selectedTabValue}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/invoice?PageSize=${rowsPerPage}&PageNumber=${page + 1
          }&active=${selectedTabValue}&searchQuery=${searchQuery}`;
      }
      const res = await axios.get(URL);

      if (res?.status === 200) {
        setLoading(false);
        const invoices = res.data.response?.map((invoice) => ({
          ...invoice,
          customerName: invoice.customer?.name || '',
          invoiceFileUpload: invoice.invoiceFileUpload[0] && invoice.invoiceFileUpload[0].fortnoxFileId,
          workingHours: invoice?.workingHours?.map((item) => ({
            ...item,
            number: item.number.toString().concat(item.unit),
            amountPerUnit: item?.amountPerUnit
              ?.toString()
              .concat(
                ' ',
                `SEK${item.unit === 'km' ? '' : '/'}${(item.unit !== 'km' && (item.unit === 'förp' ? 'Antal' : item.unit)) || ''
                }`
              ),
            expressFee: item?.expressFee ? item?.expressFee : 0,
            grossAmount: item?.grossAmount ? item?.grossAmount : 0,
          })),
          toPayAmont: invoice?.toPayAmont ? invoice?.toPayAmont : 0,
          totalGrossAmount: invoice?.totalGrossAmount ? invoice?.totalGrossAmount : 0,
          isSelected: invoice.createdBy === Number(userId),
        }));

        setoriginalInvoice(invoices);

        const filteredRows = applySortFilter(
          invoices,
          getComparator(order, orderBy),
          invoices
        );
        const invoiceId = new URLSearchParams(search).get('notificationVal');

        const notificationFilteredList = filteredRows?.map((item) => ({
          ...item,
          isSelected: item.id === Number(invoiceId),
        }));

        selectAllClick(isAllSelected, notificationFilteredList);
        setInvoices(notificationFilteredList);
        setFilteredInvoices(notificationFilteredList);
        setTotalCount(res.data.totalCount);
        if (selectedTabValue === '3') {
          setHideCheckboxColumn(false);
        } else {
          setHideCheckboxColumn(true);
        }
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        fetchInvoices();
        setLoading(true);
      } else {
        setInvoices([]);
        setFilteredInvoices([]);
        setLoading(false);
      } 
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(fetchGlobalData());
  }, [dispatch]);

  const downloadPDF = async (invoiceFileUpload) => {
    try {
      setLoading(true);
      const res = await axios.get(`/admin/invoice/file/download/${invoiceFileUpload}`);
      const linkSource = `data:application/pdf;base64,${res.data.response}`;
      const downloadLink = document.createElement('a');
      const fileName = 'sample.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const createSalary = async (id) => {
    try {
      setLoading(true);
      await axios.post(`/admin/invoice/${id}`).then(() => {
        setAlertMessage(`Salary has been created successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        fetchInvoices();
      });
      await fetchInvoices();
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      filteredInvoices,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      originalInvoice
    );
    setFilteredInvoices(filteredRows);
  };

  const selectAllClick = (isSelected, filteredInvoices) => {
    if (isSelected) {
      const newSelecteds = filteredInvoices?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, filteredInvoices);
  };

  const handleSingleSelectClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      const filteredRows = applySortFilter(
        filteredInvoices,
        getComparator(order, orderBy),
        event.target.value,
        originalInvoice
      );

      setFilteredInvoices(filteredRows);
    } else {
      setFilteredInvoices(originalInvoice);
    }
  };

  const isNotFound = filteredInvoices.length === 0;

  const handleGetSalary = async (id, isMultiFreelancer) => {
    try {
      setLoading(true);
      await axios.get(`/admin/invoice/${id}`).then((res) => {
        setSelectedRow(res.data.response);

        if (!isMultiFreelancer) {
          setIsOpenModal(() => ({ open: true }));
        }

      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getArticles = (number) => {
    switch (number) {
      case articlesNo.HourlyWages:
        return HourlyWages;
      case articlesNo.otherCost:
        return otherCost;
      case articlesNo.costPerDay:
        return costPerDayArticle;
      case articlesNo.costPerWeek:
        return costPerWeekArticle;
      case articlesNo.costPerMonth:
        return costPerMonthArticle;
      case articlesNo.costAgreement:
        return costAgreementArticle;
      case articlesNo.ExpenditureAccordingToTheAttachedPDF:
        return ExpenditureAccordingToTheAttachedPDF;
      default:
        return '';
    }
  };

  // handle edit Invoice
  const handleEditInvoice = async (id) => {
    try {
      setLoading(true);
      const URL = `/admin/invoice/get-invoice/${id}`;

      const result = await axios.get(URL);

      if (result?.status === 200) {
        const fetchInvoices = result?.data?.response;

        const { vat, amount, isFixed, customer, showDates, description, invoiceFreelancer, workingHours, file } =
          fetchInvoices;

        const filteredWorkingHours = workingHours?.filter((item) => {
          return (
            item?.articleNumber !== articlesNo?.ExpenditureAccordingToTheAttachedPDF &&
            item?.articleNumber !== articlesNo?.otherCost
          );
        });

        const freelancer =
          invoiceFreelancer?.length > 0
            ? invoiceFreelancer?.map((item) => ({
              amount: item.amount,
              userId: item.userId,
              signUpUser: item.freelancerDetails,
            }))
            : [];

        const invoiceHours = [];

        if (freelancer && freelancer.length !== 0) {
          freelancer?.forEach((freelancers) => {
            let array = [];
            filteredWorkingHours?.forEach((item) => {
              const { amountPerUnit, number, articleNumber, workingDate, userId, houseworkType, dateId } = item;
              // Check if userId is present in freelancers.userId array
              if (userId === freelancers?.userId) {
                array = [
                  {
                    ...array[0],
                    [dateId ? dateId : workingDate.split('T')[0]]: {
                      units: number,
                      amount: amountPerUnit,
                      houseworkType: {
                        value: houseworkType,
                        name: convertHouseworkType(houseworkType),
                      },
                      article: getArticles(articleNumber),
                    },
                  },
                ];
              }
            });
            invoiceHours.push(...array);
          });
        } else {
          let array = [];
          filteredWorkingHours?.forEach((item) => {
            const { amountPerUnit, number, articleNumber, workingDate, houseworkType, dateId } = item;
            array = [
              {
                ...array[0],
                [dateId ? dateId : workingDate.split('T')[0]]: {
                  units: number,
                  amount: amountPerUnit,
                  houseworkType: {
                    value: houseworkType,
                    name: convertHouseworkType(houseworkType),
                  },
                  article: getArticles(articleNumber),
                },
              },
            ];
          });
          invoiceHours.push(...array);
        }
        const element = invoiceHours.splice(invoiceHours.length - 1, 1)[0];
        invoiceHours.splice(0, 0, element);

        const otherArticles = workingHours
          ?.filter((item) => {
            return (
              item?.articleNumber === articlesNo?.otherCost ||
              item?.articleNumber === articlesNo?.ExpenditureAccordingToTheAttachedPDF
            );
          })
          .reduce((accumulator, item) => {
            let key;
            if (item?.articleNumber === articlesNo?.otherCost) {
              key = 'oth';
            } else if (item?.articleNumber === articlesNo?.ExpenditureAccordingToTheAttachedPDF) {
              key = 'exp';
            }

            const existingItem = accumulator.find((accItem) => accItem.key === key);

            if (existingItem && freelancer?.length === 0) {
              existingItem.value += item?.amountPerUnit || 0;
            } else {
              accumulator.push({
                value: item?.amountPerUnit || 0,
                userId: item?.userId,
                key,
              });
            }
            return accumulator;
          }, []);

        const selectedData = {
          selectedCustomer: customer,
          description,
          freelancer,
          invoiceHours,
          showDates,
          id,
          vat,
          isFixed,
          amount,
          file,
          fetchInvoices,
          otherArticles,
        };
        setLoading(false);
        dispatch(getInvoiceData(selectedData));
      } else {
        setLoading(false);
        toast.error('Something went wrong please try again.');
      }

      try {
        const res = await axios.get(`/admin/user/user/${result.data.response.createdBy}`);
        dispatch(invoiceCreatedUserDetails(res.data.response.user));
      } catch (err) {
        console.error(err);
      }
      navigate(`/dashboard/invoice-tool/${result.data.response.createdBy}?edit=true`);
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // notes module
  const fetchCommentList = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/admin/invoice/get-notes?invoiceId=${selectedUserId}`);
      setcommentList(res?.data?.response);
    } catch (err) {
      setcommentList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toggleNote) {
      fetchCommentList();
    }
  }, [toggleNote]);

  const addnoteToggle = (id) => {
    setToggleNote(true);
    setSelectedUserId(id);
  };
  const postAddnotes = async () => {
    try {
      if (notesContent === '' || notesContent.trim() === '') {
        setIsNotesContentEmpty(true);
        setNotesContent('');
      } else {
        setIsNotesContentEmpty(false);
        setLoading(true);
        const payload = {
          userId: 0,
          invoiceId: selectedUserId,
          description: notesContent,
        };
        const response = await axios.post('/admin/invoice/notes', payload);
        if (response?.status === 200) {
          setLoading(false);
          fetchCommentList();
          setNotesContent('');
          toast.success('Notes added successfully');
          fetchInvoices();
        } else {
          setLoading(false);
          toast.error('Server Error! Please try again');
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
      setAlertStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenFreelancerModal = (item, date) => {
    setIsOpenModal(() => ({ open: true }));
    setSelectedRow({ ...item, customer: { name: item.name }, createdDate: date });
  };

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleCloseModal = () => setIsOpenModal((prevState) => ({ ...prevState, open: false }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: 600,
    p: 4,
    overflow: 'auto',
  };

  const commentStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '60%',
    height: '80%',
    marginBottom: '40',
    p: 3,
  };

  const cancelInvoice = async (invoiceId) => {
    try {
      setLoading(true);
      await axios.put(`/admin/invoice/${invoiceId}?isActive=false`).then(async () => {
        setAlertMessage(`InvoiceId #${invoiceId} has been cancelled successfully.`);
        setAlertStatus('success');
        setAutoHideDuration(2000);
        setIsSnackBarOpen(true);
        await fetchInvoices();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const activateOrDeactivate = async (invoiceId, type) => {
    try {
      setLoading(true);
      await axios.put(`/admin/invoice/admin/${invoiceId}?isActive=${JSON.parse(type)}`).then(async () => {
        setAlertMessage(
          `InvoiceId #${invoiceId} has been ${type === 'true' ? 'Activated' : 'Deactivated'} successfully.`
        );
        setAlertStatus('success');
        setAutoHideDuration(2000);
        setIsSnackBarOpen(true);
        await fetchInvoices();
        setActivateModal({ show: false });
      });
    } catch (err) {
      setActivateModal({ show: false });
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setActivateModal({ show: false });
      setLoading(false);
    }
  };

  const handleTabChange = (e, newValue) => {
    setSearchQuery('');
    setPage(0);
    setSelected([]);
    setSelectedTabValue(newValue);
    setAllSelected(false);
    if (newValue === '1') {
      setHideCheckboxColumn(true);
    } else if (newValue === '2') {
      setHideCheckboxColumn(true);
    } else if (newValue === '3') {
      setHideCheckboxColumn(false);
    }
  };

  const handleDelete = async () => {
    await deleteInvoices();
  };

  const deleteInvoices = async () => {
    try {
      setLoading(true);
      await axios.delete('/admin/invoice', { data: selected }).then((res) => {
        const isDeletedCount = res.data.response.isDeleted;
        if (isDeletedCount > 0) {
          setAlertMessage(
            `${isDeletedCount === res.data.totalCount
              ? `Selected ${isDeletedCount > 1 ? 'invoices' : 'invoice'} has been deleted successfully.`
              : `${isDeletedCount} ${isDeletedCount > 1 ? 'invoices' : 'invoice'} out of ${res.data.totalCount
              } has been deleted successfully.`
            }`
          );
          setAlertStatus('success');
        } else {
          setAlertMessage(`Selected ${isDeletedCount > 1 ? 'invoices' : 'invoice'} can't been deleted.`);
          setAlertStatus('error');
        }
        setIsSnackBarOpen(true);
        fetchInvoices();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
      setSelected([]);
    }
  };

  const handleChangeSalaryDropdown = (event) => {
    setFreelancerName(event.target.value);
  };

  const handleUndoCreate = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/admin/invoice/delete-salary/${id}`).then(async () => {
        setAlertMessage(`Salary ${id} has been deleted successfully.`);
        setAlertStatus('success');
        setAutoHideDuration(2000);
        setIsSnackBarOpen(true);
        await fetchInvoices();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleExpressPayoutChange = (event, id) => {
    setExpressPaymentModal({
      id,
      show: true,
      type: event.target.checked,
    });
  };

  const handleCancelExpressPayment = () => {
    setExpressPaymentModal({
      show: false,
    });
  };

  const handleCreateExpressPayment = async () => {
    try {
      setLoading(true);
      await axios
        .put(`/admin/invoice/express/${expressPaymenttModal.id}?express=${expressPaymenttModal.type}`)
        .then(async (res) => {
          setAlertMessage(res?.data?.response);
          setAlertStatus('success');
          setAutoHideDuration(2000);
          setIsSnackBarOpen(true);
          const updatedInvoices = filteredInvoices?.map((row) => {
            if (row.id === expressPaymenttModal.id) {
              return { ...row, expressPayout: expressPaymenttModal.type };
            }
            return row;
          });
          setFilteredInvoices(updatedInvoices);
          setExpressPaymentModal({
            show: false,
          });
          await fetchInvoices();
        });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Activate API Function Helper
  const handleActivateInvoice = async (invoiceId, type) => {
    try {
      setLoading(true);
      const result = await axios.put(`/admin/invoice/admin/${invoiceId}?isActive=${JSON.parse(type)}`);
      if (result?.status === 200) {
        setAlertMessage(`InvoiceId #${invoiceId} has been ${type === 'true' && 'Activated'} successfully.`);
        setAlertStatus('success');
        setAutoHideDuration(2000);
        setIsSnackBarOpen(true);
        await fetchInvoices();
        settoggleIsActive({ show: false });
      }
    } catch (err) {
      settoggleIsActive({ show: false });
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      settoggleIsActive({ show: false });
      setLoading(false);
    }
  };

  const handlePaidSalary = async (data) => {
    const { id } = data;
    try {
      setLoading(true);
      let url;
      if (data?.type === 'unpaid') {
        url = `/admin/invoice/changes-in-status-of-invoice?invoiceId=${id}`;
      } else {
        url = `/admin/adminsalary/create-salary?id=${id}&isPaid=${true}`;
      }
      await axios.post(url).then(() => {
        setAlertMessage(`Invoice has been ${data?.type === 'unpaid' ? 'unpaid' : 'paid'}  successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        fetchInvoices();
      });
      await fetchInvoices();
    } catch (err) {
      setAlertMessage(err?.response?.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSalaryStatus = async (data) => {
    const { id } = data;
    try {
      setLoading(true);
      const url = `/admin/invoice/manually-salarystatus-change?invoiceId=${id}`;
      await axios.post(url).then(() => {
        setAlertMessage(`Status has been updated successfully.`);
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        fetchInvoices();
      });
      await fetchInvoices();
    } catch (err) {
      setAlertMessage(err?.response?.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSyncProceed = async () => {
    try {
      setLoading(true);
      const Url = '/admin/invoice/sync-all-data-to-fortnox';
      const result = await axios.post(Url);

      if (result?.data?.message === 'error') {
        setMessages({
          customerUpdatedOnFortnoxError: result?.data?.response?.customerUpdatedOnFortnoxError,
          customeraddedOnFortnoxError: result?.data?.response?.customeraddedOnFortnoxError,
          invoiceCanceledOnFortnoxError: result?.data?.response?.invoiceCanceledOnFortnoxError,
          invoiceFileuploadedOnFortnoxError: result?.data?.response?.invoiceFileuploadedOnFortnoxError,
          invoiceTaxReductioncreatedOnFortnoxError: result?.data?.response?.invoiceTaxReductioncreatedOnFortnoxError,
          invoiceUpdatedOnFortnoxError: result?.data?.response?.invoiceUpdatedOnFortnoxError,
          invoiceaddedOnFortnoxError: result?.data?.response?.invoiceaddedOnFortnoxError,

          // Without Error Data
          customerUpdatedOnFortnox: result?.data?.response?.customerUpdatedOnFortnox,
          customeraddedOnFortnox: result?.data?.response?.customeraddedOnFortnox,
          invoiceCanceledOnFortnox: result?.data?.response?.invoiceCanceledOnFortnox,
          invoiceFileuploadedOnFortnox: result?.data?.response?.invoiceFileuploadedOnFortnox,
          invoiceTaxReductioncreatedOnFortnox: result?.data?.response?.invoiceTaxReductioncreatedOnFortnox,
          invoiceUpdatedOnFortnox: result?.data?.response?.invoiceUpdatedOnFortnox,
          invoiceaddedOnFortnox: result?.data?.response?.invoiceaddedOnFortnox,
        });
        setisError(true);
        setshowSyncModal(false);
        toast.error('Fortnox token is invalid');
      }
      if (result?.data?.message === 'Success') {
        toast.success('The Entire data of customer and invoice is sync to fortnox');
        setMessages({
          customerUpdatedOnFortnox: result?.data?.response?.customerUpdatedOnFortnox,
          customeraddedOnFortnox: result?.data?.response?.customeraddedOnFortnox,
          invoiceCanceledOnFortnox: result?.data?.response?.invoiceCanceledOnFortnox,
          invoiceFileuploadedOnFortnox: result?.data?.response?.invoiceFileuploadedOnFortnox,
          invoiceTaxReductioncreatedOnFortnox: result?.data?.response?.invoiceTaxReductioncreatedOnFortnox,
          invoiceUpdatedOnFortnox: result?.data?.response?.invoiceUpdatedOnFortnox,
          invoiceaddedOnFortnox: result?.data?.response?.invoiceaddedOnFortnox,
        });
        setisError(true);
      }
      fetchInvoices();
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
      setshowSyncModal(false);
    }
  };

  const handleButtonClick = async (customerId) => {
    setLoading(true);
    try {
      const result = await axios.post(`/admin/user/sent-incorrect-info-mail?id=${customerId}&type=1`);
      if (result?.data?.message === 'Success') {
        toast.success(result?.data?.response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const renderInvoices = () => (
    <>
      <ListToolbar
        hideEmailButton={'true'}
        numSelected={selected.length}
        searchQuery={searchQuery}
        onFilter={handleFilter}
        onDelete={handleDelete}
        dropOptions={invoiceDropOptions}
        searchDropValue={searchDropValue}
        setSearchDropValue={setSearchDropValue}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table className="invoice-details">
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={invoices.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              hideCheckboxColumnHeader={hideCheckboxColumn}
            />
            <TableBody>
              {filteredInvoices?.map((row, tableIndexs) => {
                const {
                  id,
                  amount,
                  isActive,
                  createdDate,
                  customerName,
                  invoiceStatus,
                  expressPayout,
                  isPfdAttached,
                  invoiceSalaryStatus,
                  dueDate,
                  multipleFreelancer,
                  notes,
                  customer,
                  isSalaryCreated,
                  invoiceType,
                } = row;

                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    style={{
                      backgroundColor: row?.isSelected
                        ? 'lightgrey'
                        : !row?.isInvoiceAddedOnFortnox ||
                          !row?.isInvoiceUpdatedOnFortnox ||
                          !row?.isInvoiceCanceledOnFortnox ||
                          !row?.isTaxReductioncreatedOnFortnox ||
                          !row?.isFileuploadedOnFortnox
                          ? 'rgb(248 232 232)'
                          : '',
                    }}
                  >
                    {!hideCheckboxColumn && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleSingleSelectClick(event, id)}
                          size="small"
                        />
                      </TableCell>
                    )}

                    <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                      {id}
                    </TableCell>

                    <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                      {invoiceType === 2
                          ? 'E-invoice'
                          : invoiceType === 3
                            ? 'Post'
                            : 'Email'}
                    </TableCell>

                    {customerName?.length >= 9 ? (
                      <Tooltip title={customerName} arrow placement="top">
                        <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                          {customerName?.substring(0, 9).concat('...')}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                        {customerName}
                      </TableCell>
                    )}

                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {row?.createdBy}
                    </TableCell>

                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {row?.userName}
                    </TableCell>

                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {createdDate.split('T')[0]}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {dueDate && dueDate.split('T')[0]}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '12px', padding: '6px' }}>
                      {amount}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                      {invoiceStatus && (
                        <Label variant="ghost" color={invoiceStatus === 1 ? 'error' : 'success'}>
                          {invoiceStatus === 1 ? (
                            <span
                              style={{ cursor: row?.isSalaryStatusUpdatedManually ? 'auto' : 'pointer' }}
                              onClick={() => {
                                !row?.isSalaryStatusUpdatedManually &&
                                  setIstogglePaidUnpaid({
                                    id,
                                    show: true,
                                    type: 'paid',
                                  });
                              }}
                            >
                              Unpaid
                            </span>
                          ) : invoiceStatus === 2 ? (
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setIstogglePaidUnpaid({
                                  id,
                                  show: true,
                                  type: 'unpaid',
                                });
                              }}
                            >
                              {'Paid'}
                            </span>
                          ) : (
                            'Partial'
                          )}
                        </Label>
                      )}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                      {invoiceSalaryStatus && (
                        <Label variant="ghost" color={invoiceSalaryStatus === 1 ? 'error' : 'success'}>
                          {invoiceSalaryStatus === 1 ? (
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setisChangeStatus({
                                  id,
                                  show: true,
                                  type: 'paid',
                                });
                              }}
                            >
                              Unpaid
                            </span>
                          ) : (
                            sentenceCase(invoiceSalaryStatus === 2 ? 'Salary Paid' : 'Partial')
                          )}
                        </Label>
                      )}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '13px', padding: '6px' }}>
                      <input
                        disabled={customer?.paymentTerm === '45' || customer?.paymentTerm === '60'}
                        type="checkbox"
                        checked={expressPayout}
                        onChange={(event) => handleExpressPayoutChange(event, id)}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        marginTop: '-10px',
                        fontSize: '12px',
                        padding: '6px',
                      }}
                    >
                      {!multipleFreelancer ? (
                        <Tooltip title="Salary Details">
                          <Button
                            variant="outlined"
                            onClick={() => handleGetSalary(id, false)}
                            style={{
                              fontSize: '12px',
                              padding: '6px',
                              width: 70,
                            }}
                          >
                            Salary Details
                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <FormControl sx={{ m: 1, minWidth: 110, margin: 0 }} variant="standard">
                            <InputLabel id="multi-freelancer-label" style={{ fontSize: '13px' }}>
                              Salary Details
                            </InputLabel>
                            <Select
                              defaultValue="choose"
                              labelId="multi-freelancer-label"
                              value={(tableIndexs === isOpenModal.index && freelancerName) || ''}
                              label="Salary Details"
                              onOpen={() => handleGetSalary(id, true)}
                              onChange={(event) => handleChangeSalaryDropdown(event, tableIndexs)}
                            >
                              {loading ? (
                                <MenuItem value="">Loading...</MenuItem>
                              ) : (
                                selectedRow?.salaryFreelancer?.map((items) => {
                                  const { userName, userId } = items;

                                  return (
                                    <MenuItem
                                      key={userId}
                                      value={userName}
                                      onClick={() => handleOpenFreelancerModal(items, selectedRow?.createdDate)}
                                      className={
                                        selectedRow.salaryFreelancer.find(
                                          (item) => item.userId === selectedRow.createdBy
                                        )?.userId === userId
                                          ? 'highlight-user'
                                          : ''
                                      }
                                    >
                                      {userName}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '6px',
                      }}
                    >
                      <Tooltip title="Edit">
                        <BorderColorIcon
                          style={{
                            gap: '7px',
                            marginLeft: '8px',
                            color: '#fff',
                            borderRadius: '100px',
                            width: '30px',
                            height: '30px',
                            padding: '6px',
                            cursor: 'pointer',
                            background: '#62b5e5',
                          }}
                          onClick={() => {
                            handleEditInvoice(id);
                          }}
                        >
                          <Iconify icon="material-symbols:edit" />
                        </BorderColorIcon>
                      </Tooltip>

                      <Tooltip title="Notes">
                        <Button
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Notes"
                          variant={notes ? '' : 'contained'}
                          onClick={() => {
                            addnoteToggle(id);
                          }}
                          style={{
                            width: 50,
                            minWidth: '50px',
                            fontSize: '12px',
                            padding: '6px',
                            marginLeft: '8px',
                          }}
                        >
                          <NoteIcon style={{ color: notes && 'rgb(226,204,97)' }}>NoteIcon</NoteIcon>
                        </Button>
                      </Tooltip>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                        }}
                      >
                        {(isSalaryCreated === true && isActive && invoiceSalaryStatus === 1 && (
                          <div>
                            <Button
                              variant="outlined"
                              color={'error'}
                              style={{ fontSize: '12px', width: 50, minWidth: '50px', color: 'rgb(195,65,82)' }}
                              sx={{
                                padding: '10px 4px',
                                height: 35,
                                fontSize: '12px',
                                width: 70,
                                minWidth: '70px',
                              }}
                              onClick={() => {
                                setCreateAccountModal({
                                  id,
                                  show: true,
                                  type: 'undo',
                                });
                              }}
                            >
                              {sentenceCase('Undo')}
                            </Button>
                          </div>
                        )) ||
                          (isSalaryCreated === true && isActive && invoiceSalaryStatus === 2 && (
                            <div>
                              <Button
                                color={'success'}
                                style={{ fontSize: '12px', width: 50, minWidth: '50px' }}
                                sx={{
                                  padding: '10px 4px',
                                  height: 35,
                                  fontSize: '12px',
                                  width: 70,
                                  minWidth: '70px',
                                }}
                              >
                                {row?.isSalaryStatusUpdatedManually ? (
                                  <Tooltip title="Manually Paid">
                                    <Button style={{ cursor: 'auto' }}>
                                      {' '}
                                      <CreditScoreIcon />{' '}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Digitally Paid">
                                    <Button style={{ cursor: 'auto' }}>
                                      <PaidIcon color="success" />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Button>
                            </div>
                          )) ||
                          (isSalaryCreated === true && !isActive && (
                            <div>
                              <Tooltip title="Created">
                                <Button color={'success'} style={{ fontSize: '12px', width: 50, minWidth: '50px' }}>
                                  <CheckCircleIcon style={{ color: 'rgb(37, 98, 82)' }} />
                                </Button>
                              </Tooltip>
                            </div>
                          )) || (
                            <div>
                              <Tooltip title="Create Salary">
                                <Button
                                  disabled={row?.isSalaryStatusUpdatedManually}
                                  variant="outlined"
                                  onClick={() => {
                                    setCreateAccountModal({
                                      id,
                                      show: true,
                                      type: 'create',
                                    });
                                  }}
                                  style={{ fontSize: '12px', width: 50, minWidth: '50px' }}
                                >
                                  <NoteAddIcon style={{ color: '#62b5e5' }}>Create</NoteAddIcon>
                                </Button>
                              </Tooltip>
                            </div>
                          )}

                        {!isActive && (
                          <Tooltip title="Click To Activate">
                            <Button
                              variant="contained"
                              color={'error'}
                              value={'false'}
                              style={{
                                background: 'rgb(96 179 227)',
                                width: 50,
                                minWidth: '50px',
                                fontSize: '12px',
                              }}
                              onClick={() => {
                                settoggleIsActive({ id, show: true });
                              }}
                            >
                              <AddCircleOutlineIcon />
                            </Button>
                          </Tooltip>
                        )}

                        {isActive ? (
                          <Tooltip title="Click To Deactivate">
                            <Button
                              variant="contained"
                              color={'error'}
                              value={'false'}
                              style={{
                                background: '#c34152',
                                width: 50,
                                minWidth: '50px',
                                fontSize: '12px',
                              }}
                              onClick={() => {
                                setActivateModal({ id, show: true });
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            variant="contained"
                            color={isActive ? 'primary' : 'error'}
                            style={{
                              width: 70,
                              minWidth: '70px',
                              fontSize: '12px',
                            }}
                            disabled
                          >
                            Cancelled
                          </Button>
                        )}
                      </div>
                      <div>
                        {isPfdAttached && (
                          <Tooltip title="PDF">
                            <PictureAsPdfIcon
                              variant="contained"
                              color="error"
                              style={{
                                background: '#62b5e5',
                                width: 28,
                                minWidth: '28px',
                                fontSize: '28px',
                              }}
                              onClick={() => {
                                downloadPDF(id);
                              }}
                              sx={{
                                ml: 1,
                                color: '#fff',
                                cursor: 'pointer',
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>

                      <TableCell style={{ fontSize: '13px', padding: '6px' }}>
                        {isActive && (
                          <Tooltip title="Cancel">
                            <Button
                              variant="outlined"
                              color={isActive ? 'error' : 'primary'}
                              style={{
                                width: 50,
                                minWidth: '50px',
                                fontSize: '12px',
                              }}
                              onClick={() => {
                                setCreateAccountModal({
                                  id,
                                  show: true,
                                  type: 'cancel',
                                });
                              }}
                            >
                              <CancelIcon style={{ color: 'rgb(195,65,82)' }}>Cancel</CancelIcon>
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>

                      <Tooltip title="View logs">
                        <Button
                          type="submit"
                          onClick={() =>
                            navigate(`/dashboard/view-logs?type=invoice&id=${id}`, {
                              state: {
                                type: 'name',
                                name: row?.userName,
                              },
                            })
                          }
                          style={{
                            width: 50,
                            minWidth: '50px',
                            fontSize: '12px',
                            padding: '6px',
                            marginLeft: '8px',
                          }}
                        >
                          <HistoryIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <Modal
              open={isOpenModal.open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box sx={{ height: 'fit-content' }} id="salary-modal">
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Salary Details <br />
                    User Id: #{selectedRow?.userId ? selectedRow?.userId : selectedRow?.createdBy}
                    <br />
                    User Name: {selectedRow?.userName}
                    <br />
                    {selectedRow?.customer?.name ? 'Customer Name:' : ''} {selectedRow?.customer?.name || ''}
                  </Typography>

                  <div>
                    <div
                      className="table-scroll"
                      style={{
                        maxHeight: '160px',
                        overflow: 'auto',
                        margin: '0 0 20px 0',
                      }}
                    >
                      <Table className="salary-details">
                        <ListHead
                          headLabel={WORKING_HOURS_TABLE_HEAD}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                          hideCheckboxColumnHeader={hideCheckboxColumn}
                        />
                        <TableBody>
                          {selectedRow?.workingHours?.map((row, i) => {
                            const {
                              number,
                              expressFee,
                              workingDate,
                              articleName,
                              totalAmount,
                              grossAmount,
                              amountPerUnit,
                            } = row;
                            return (
                              <TableRow hover key={i} tabIndex={-1}>
                                <TableCell align="left" style={{ padding: '6px 16px' }} scope="row">
                                  {formatDate2(workingDate)}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {articleName}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {number}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {amountPerUnit}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {totalAmount || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {grossAmount?.toFixed(2) || 0}
                                </TableCell>
                                <TableCell align="left" style={{ padding: '6px 16px' }}>
                                  {expressFee || 0}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                    {selectedRow?.workingHours?.length && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Hours
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.totalHourlyWagesHours?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Hours Per Unit Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.hourlyWagesAmountPerUnit?.toFixed(2) : 0}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Amount INCL. VAT
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.vatAmount} {selectedRow?.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Invoice Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {!selectedRow?.isFixed ? selectedRow?.amount?.toFixed(2) : 0} {selectedRow?.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Fixed Price
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.isFixed ? selectedRow?.amount?.toFixed(2) : 0} {selectedRow?.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Invozio Fee
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.multipleFreelancer === false
                                ? selectedRow?.fakturaNuFee?.toFixed(2) || '0'
                                : selectedRow?.fakturaFee?.toFixed(2) || '0'}{' '}
                              SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Post Fee
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.multipleFreelancer === false
                                ? selectedRow?.totalPostFeeAmount?.toFixed(2) || '0'
                                : selectedRow?.totalPostFeeAmount?.toFixed(2) || '0'}{' '}
                              SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Express Payout Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.multipleFreelancer === false
                                ? selectedRow?.expressPayoutAmount?.toFixed(2) || '0'
                                : selectedRow?.expressPayoutAmount?.toFixed(2) || '0'}{' '}
                              SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Pension Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.pensionAmount?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Holiday Pay Amount
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.holidaySavingsAmount?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              VAT%
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.vat}
                            </TableCell>
                          </TableRow>

                          {new Date(selectedRow.createdDate) > new Date('2023-12-31T23:59:59') ? (
                            ''
                          ) : (
                            <TableRow>
                              <TableCell
                                style={{ padding: '7px 10px' }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3, fontWeight: 'bold' }}
                              >
                                Administrator Fee
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '7px 10px',
                                  textAlign: 'right',
                                }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                {selectedRow?.administratorFee?.toFixed(2) || 0}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total Gross Salary
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.totalGrossAmount?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Tax Free Article
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.taxfreeArticle?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>

                          {selectedRow?.multipleFreelancer === false ? (
                            <TableRow>
                              <TableCell
                                style={{ padding: '7px 10px' }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3, fontWeight: 'bold' }}
                              >
                                Travel And Allowence
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '7px 10px',
                                  textAlign: 'right',
                                }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                {selectedRow?.totalTravelAllowance?.toFixed(2) || '0'} SEK
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                style={{ padding: '7px 10px' }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3, fontWeight: 'bold' }}
                              >
                                Travel And Allowence
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '7px 10px',
                                  textAlign: 'right',
                                }}
                                align="left"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                {selectedRow?.travelAllowance?.toFixed(2) || '0'} SEK
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Express Payout
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.expressPayout ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Pension
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.pension ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Holiday Pay
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.holidaySavings ? 'Yes' : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Foreign Bank Fee
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {selectedRow?.foreignBankFee?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: '7px 10px' }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              Total amount to be paid to bank
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '7px 10px',
                                textAlign: 'right',
                                minWidth: 90,
                              }}
                              align="left"
                              colSpan={6}
                              sx={{ py: 3, fontWeight: 'bold' }}
                            >
                              {selectedRow?.toPayAmont?.toFixed(2)} SEK
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                  </div>
                </Box>
                <div
                  style={{
                    margin: '25px 0 0',
                    float: 'right',
                    gap: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PictureAsPdfIcon
                    onClick={() => {
                      downloadSalaryDetailsAsPdf();
                    }}
                    sx={{
                      ml: 1,
                      background: '#62b5e5',
                      color: '#fff',
                      mt: 7,
                      cursor: 'pointer',
                      marginTop: '0',
                    }}
                  />
                  <Button onClick={handleCloseModal} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                    Close
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={activateModal.show}
              onClose={() => setActivateModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to deactivate this invoice?
                </Typography>
                <div
                  style={{
                    margin: '25px 0 0',
                    float: 'right',
                    gap: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    onClick={() => setActivateModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => activateOrDeactivate(activateModal.id, 'false')}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={createAccountModal.show}
              onClose={() => setCreateAccountModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {(createAccountModal.type === 'create' && 'Are you sure you want to create salary?') ||
                    (createAccountModal.type === 'cancel' && 'Are you sure you want to cancel?') ||
                    'Are you sure you want to delete salary?'}
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setCreateAccountModal({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      if (createAccountModal.type === 'create') {
                        createSalary(createAccountModal.id);
                      } else if (createAccountModal.type === 'cancel') {
                        cancelInvoice(createAccountModal.id);
                      } else {
                        handleUndoCreate(createAccountModal.id);
                      }

                      setCreateAccountModal({ ...createAccountModal, show: false });
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={expressPaymenttModal.show}
              onClose={() => setExpressPaymentModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {expressPaymenttModal.type
                    ? `Are you sure you want to pay express payment for user #${expressPaymenttModal.id} ?`
                    : `Are you sure you want to cancel express payment for user #${expressPaymenttModal.id} ?`}
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={handleCancelExpressPayment}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                    onClick={handleCreateExpressPayment}
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={IstogglePaidUnpaid.show}
              onClose={() => setCreateAccountModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {IstogglePaidUnpaid.type === 'paid' && 'Are you sure you want to paid invoice?'}
                  {IstogglePaidUnpaid.type === 'unpaid' && 'Are you sure you want to unpaid invoice?'}
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setIstogglePaidUnpaid({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      if (IstogglePaidUnpaid.type === 'paid' || IstogglePaidUnpaid.type === 'unpaid') {
                        handlePaidSalary(IstogglePaidUnpaid);
                      }
                      setIstogglePaidUnpaid({ ...IstogglePaidUnpaid, show: false });
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={isChangeStatus.show}
              onClose={() => setCreateAccountModal({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {isChangeStatus.type === 'paid' && 'Are you sure you want to change salary status?'}
                </Typography>
                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => setisChangeStatus({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      if (isChangeStatus.type === 'paid') {
                        handleChangeSalaryStatus(isChangeStatus);
                      }
                      setisChangeStatus({ ...isChangeStatus, show: false });
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            {/* Add Notes  Modal */}
            <Modal
              open={toggleNote}
              onClose={() => {
                setToggleNote(false);
                setNotesContent('');
                setIsNotesContentEmpty(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={commentStyle}>
                <Box sx={{ height: 'fit-content' }} id="usersList-modal">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setToggleNote(false);
                      setNotesContent('');
                      setIsNotesContentEmpty(false);
                    }}
                    sx={{
                      position: 'absolute',
                      right: 5,
                      top: 0,
                    }}
                    aria-label="close"
                  >
                    <ClearIcon style={{ fontSize: 26 }} />
                  </IconButton>
                  <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: '95%' }}>
                    Notes
                  </Typography>
                  <Form>
                    <Form.Group
                      className="modalform"
                      style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        style={{
                          resize: 'none',
                          width: '50%',
                          borderRadius: '12px',
                          borderColor: isNotesContentEmpty && '#eb5e5e',
                        }}
                        onChange={(e) => setNotesContent(e.target.value)}
                        value={notesContent}
                      />
                      <Button variant="contained" style={{ height: '40px', marginLeft: '20px' }} onClick={postAddnotes}>
                        <AddCircleIcon />
                      </Button>
                    </Form.Group>
                  </Form>
                  <div style={{ height: '250px', overflow: 'auto', marginTop: '10px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date </TableCell>
                          <TableCell>Message</TableCell>
                          <TableCell>Added By</TableCell>
                        </TableRow>
                      </TableHead>
                      {loading ? (
                        ''
                      ) : (
                        <TableBody>
                          {commentList?.length > 0 ? (
                            commentList?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell> {formatDate(row?.createdDate)}</TableCell>
                                <TableCell>
                                  {row?.description?.length > 30 ? (
                                    <TooltipWithCopy text={row?.description} />
                                  ) : (
                                    row?.description
                                  )}
                                </TableCell>
                                <TableCell> {row?.addedBy} </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow className="text-center">
                              <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                No record found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      )}
                    </Table>
                  </div>
                </Box>
              </Box>
            </Modal>

            {/* Activate Invoice */}

            <Modal
              open={toggleIsActive.show}
              onClose={() => settoggleIsActive({ show: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to activate this invoice?
                </Typography>
                <div
                  style={{
                    margin: '25px 0 0',
                    float: 'right',
                    gap: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    onClick={() => settoggleIsActive({ show: false })}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => handleActivateInvoice(toggleIsActive.id, 'true')}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showSyncModal}
              onClose={(prevState) => {
                setshowSyncModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box sx={getModalBoxStyle()}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to sync?
                </Typography>

                <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={(prevState) => {
                      setshowSyncModal(false);
                    }}
                    sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                    autoFocus
                  >
                    No
                  </Button>

                  {!isError && (
                    <Button onClick={handleSyncProceed} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                      Yes
                    </Button>
                  )}
                </div>
              </Box>
            </Modal>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchQuery} isLoading={loading} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[50, 100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
  return (
    <>
      <Page title="Invoices">
        {loading && <Loader />}
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h4" sx={{ ml: 1 }}>
                Invoices
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Tooltip arrow title={'Sync To Fortnox'}>
                <Button style={{ marginLeft: '10px' }} variant="contained" onClick={() => setshowSyncModal(true)}>
                  Sync To Fortnox
                </Button>
              </Tooltip>
            </Stack>
          </Stack>

          <ErrosAlert
            isError={isError}
            messages={messages}
            setshowSyncModal={setshowSyncModal}
            setisError={setisError}
            setMessages={setMessages}
            handleButtonClick={handleButtonClick}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isSnackBarOpen}
            autoHideDuration={autoHideDuration}
            onClose={handleSnackBarClose}
          >
            <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
          <Card>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={selectedTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} aria-label="lab API tabs example">
                    <Tab label="ACTIVE" value="2" />
                    <Tab label="INACTIVE" value="3" />
                    <Tab label="ALL" value="1" />{' '}
                  </TabList>
                </Box>
                <TabPanel value="1">{renderInvoices()}</TabPanel>
                <TabPanel value="2">{renderInvoices()}</TabPanel>
                <TabPanel value="3">{renderInvoices()}</TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
